const config = {
  development: {
    apiEndpoint: "https://symliphybackend.azurewebsites.net/rest/v1",
    homePage: "http://localhost:3000/confirmation/",
    baseUrl: "http://localhost:3000",
  },
  staging: {
    apiEndpoint: "http://localhost:8080/rest/v1",
    homePage: "http://beta.symliphy.com",
    baseUrl: "http://beta.symliphy.com",
  },
  production: {
    apiEndpoint: "https://symliphybackend.azurewebsites.net/rest/v1",
    homePage: "http://www.symliphy.com",
    baseUrl: "http://www.symliphy.com",
  },
};

export default config[process.env.REACT_APP_ENV || 'development'];
