// Config starter code
import { createChatBotMessage } from "react-chatbot-kit";
import TreatmentOptionsWidget from './TreatmentOptionsWidget.jsx';

const botName = 'Symliphy';

const config = {
  initialMessages: [
    createChatBotMessage('Welcome to Symliphy!',{
      withAvatar: false,
      delay: 0
    }),
    createChatBotMessage('In a few words, please describe the symptoms you are experiencing.',{
      withAvatar: false,
      delay: 0
    }),
  ],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: '',
    },
    chatButton: {
      backgroundColor: '#F26739',
    },
  },
  widgets: [
    {
      widgetName: "treatmentOptionsWidget",
      widgetFunc: (props) => <TreatmentOptionsWidget {...props} />,
      mapStateToProps: ["treatmentOptions"], // Map treatmentOptions from state to widget props
    },
  ]
};

export default config