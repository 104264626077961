import {
  Flex, Text, Grid, Box, Button
} from '@chakra-ui/react'
import { Questions } from '../data/_faqs.ts'
import { useNavigate } from 'react-router-dom'
  
export const FAQsBlock = ({titleColor = 'primaryYellow.default'}) => {

  const navigate = useNavigate();

  const handleFAQClick = () => {
    navigate('/faqs');
  }

  return (
    <>
      <Flex
        as="section"
        background={'white'}
        py={{ base: 8, md: 12 }}
        px={{ base: 8, md: 24 }}
        width="100%"
        justifyContent={'space-between'}
        flexDirection={'column'}
        textAlign={'center'}
        alignItems={'center'}
        gap={5}
      >
        <Text fontSize={'30px'} 
          color={titleColor} 
          fontWeight={'bold'}
        >
          FREQUENTLY ASKED QUESTIONS
        </Text>
        <Grid 
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} 
          gap={6} mt={4} position="relative"
        >
          {Questions.slice(0,4).map((service, index) => (
            <Box 
              key={index} textAlign="center" 
              p={5} shadow={'none'} borderWidth="0px" 
              w={{base:'80vw', md:'45vw', lg:'450px'}} maxW={'750px'} 
              position="relative"
            >
              <Flex 
                fontSize="xl" mt="4" cursor="pointer" 
                w="100%" justifyContent="center" alignItems="center" 
                color={'primaryBlue.darken80'} fontWeight={'bold'}
              >
                {service.title}
              </Flex>
              <Text mt="3">
                {service.description}
              </Text>
            </Box>
          ))}
        </Grid>
        <Button variant="primaryYellowSolid" size="lg" mt={5} onClick={handleFAQClick}>See All FAQs</Button>
      </Flex>
    </>
  )
}