import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Footer } from '../components/Footer';
import { IndividualHeaderText } from '../components/IndividualHeaderText';
import { Navbar } from '../components/Navbar';
import { TreatmentsGrid } from '../components/TreatmentsGrid';

export const Care = () => {
  const treatmentsGridRef = useRef(null);
  const location = useLocation();

  const scrollToTreatmentsGrid = () => {
    treatmentsGridRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('show') === 'treatments') {
      scrollToTreatmentsGrid();
    }
  }, [location]);

  return (
    <>
      <Navbar onButtonClick={scrollToTreatmentsGrid} />
      <IndividualHeaderText text="All Treatments" />
      <TreatmentsGrid />
      <Footer />
    </>
  );
};
