import { Box, Button, Flex, Modal, ModalCloseButton, ModalContent, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ActionBar } from '../components/ActionBar.jsx';
import { Footer } from '../components/Footer.jsx';
import { Navbar } from '../components/Navbar.jsx';
import { TreatmentDetails } from '../components/TreatmentDetails.jsx';
import { TreatmentHeader } from '../components/TreatmentHeader.jsx';
import { categories } from '../data/_categories.ts';
import { treatmentData } from '../data/_treatments.ts';

export const Category = () => {

  const { pathname } = useLocation();
  const [treatmentList,setTreatmentList] = useState(null);
  const [categoryName,setCategoryName] = useState('');
  const [categoryDescription,setCategoryDescription] = useState('');
  const [categoryImage,setCategoryImage] = useState(null);
  const [selectedTreatment,setSelectedTreatment] = useState(null);
  const [treatmentDetails,setTreatmentDetails] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  //console.log("Location",pathname)

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    console.log('Original Pathname:', pathname);
  
    // Directly extract the category name from the URL
    const pathSegments = pathname.split('/');
    //console.log('pathSegments',pathSegments)
    // Assuming the category name is always after '/category/' or '/categories/'
    const categoryNameFromUrl = pathSegments.find(segment => segment === 'category' || segment === 'categories') ? pathSegments[pathSegments.indexOf('category') + 1] || pathSegments[pathSegments.indexOf('categories') + 1] : null;

    const categoryNameFromUrlLowerCase = categoryNameFromUrl.toLowerCase() || null;
  
    console.log('Category Name from URL Lower Case:', categoryNameFromUrlLowerCase);
  
    if (categoryNameFromUrlLowerCase) {
      // Use the categoryNameFromUrl to find the matching category based on the urlPath key
      const matchingCategory = categories.find(category => category.urlPath === categoryNameFromUrlLowerCase);
  
      console.log('Matching Category:', matchingCategory);
      setCategoryName(matchingCategory.title)
      setCategoryDescription(matchingCategory.description)
      setCategoryImage(matchingCategory.image)
  
      if (matchingCategory) {
        // Set the treatment list based on the matching category
        setTreatmentList(matchingCategory.treatments);
      } else {
        // Reset the treatment list if no matching category is found
        console.error('No matching category found for URL:', categoryNameFromUrlLowerCase);
        setTreatmentList([]);
      }
    } else {
      console.error('Unable to extract category name from URL');
      // Handle the case where the category name could not be extracted
      // This could involve setting a default state, showing an error message, etc.
    }
  }, [pathname, categories]);

  const handleSelectTreatment = (treatment) => {
    setSelectedTreatment(treatment);
    onOpen(); // Open the modal when a treatment is selected
  };
  

  // Function to update the state in the parent component
  const handleProtocolNameChange = (newProtocolName) => {
    setSelectedTreatment(newProtocolName);
  };

  // Update content based on protocolName
  useEffect(() => {
    //console.log("protocolName",selectedTreatment)
    if (selectedTreatment) {
      const matchingTreatment = treatmentData.find(treatment => treatment.name === selectedTreatment);
      if (matchingTreatment) {
        setTreatmentDetails(matchingTreatment);
      } else {
        setTreatmentDetails(null);
      }
      //console.log('matchingTreatment',matchingTreatment)
    }
    //console.log('protocolName',selectedTreatment)
  }, [selectedTreatment]);
  

  return (
    treatmentList &&
    <>
      <Navbar />
      <TreatmentHeader 
        name={categoryName}
        description={categoryDescription}
        image={categoryImage}
      />
      
      <Flex flexDirection={{ base: 'column', md: 'row' }} maxW={'1280px'} w={'100vw'} 
        margin={'0 auto'} justifyContent={'start'} py={4} minH={'500px'}
      >
        {isMobile && 
          <Text px={4} py={3} fontSize='xl' fontWeight='700' color='primaryBlue.default'>Available Treatments:</Text>
        }
        <Tabs variant={{base:"enclosed-colored",md:"enclosed"}} orientation="vertical" isLazy maxW={'1310px'} w={'100vw'}>
          <TabList w={{ base: '100%', md: '250px' }}>
            {treatmentList.map((treatment, index) => (
              <Tab key={index} fontWeight="bold" justifyContent={'left'} onClick={() => isMobile ? handleSelectTreatment(treatment) : setSelectedTreatment(treatment)}>
                {treatment}
              </Tab>
            ))}
          </TabList>
          {!isMobile && (
            <TabPanels w={{base:'100%', md:'calc(100% - 250px)'}}>
              {treatmentList.map((treatment, index) => {
                const matchingTreatmentData = treatmentData.find(data => data.name === treatment);

                //console.log("Treatment Name:", treatment);

                return (
                  <TabPanel key={index} py={0}>
                    <Button mt={4} mx={8} size={'lg'} variant={'primaryOrangeSolid'} onClick={() => handleSelectTreatment(treatment)} position={'absolute'} right='0'>Get Treatment</Button>
                    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                      <Text fontSize={'3xl'} fontWeight='bold' mt={2} px={8} pt={3}>{treatment}</Text>
                      {matchingTreatmentData &&
                      <TreatmentDetails 
                        name={matchingTreatmentData.name}
                        whatYouNeed={matchingTreatmentData.whatYouNeed}
                        symptoms={matchingTreatmentData.symptoms}
                        commonMedications={matchingTreatmentData.commonMedications}
                      />
                      }
                      <Button mt={matchingTreatmentData ? -8 : 4} mx={8} mb={8} size={'lg'} w={'100%'} maxW={'350px'} variant={'primaryOrangeSolid'} onClick={() => handleSelectTreatment(treatment)}>{treatment==='Medication Refill' || ( treatment && treatment.includes('General Consult')) ? `Start ${treatment}` : 'Get Started'}</Button>
                    </Box>
                  </TabPanel>
                );
              })}
            </TabPanels>
          )}
        </Tabs>
      </Flex>
      <Footer />

      {/* Modal for ActionBar */}
      <Flex>
        <Modal isOpen={isOpen} onClose={onClose} maxW='300px' 
          h={'100%'} maxH={'calc(100vh - 195px)'} right='0px' overflow={'auto'}
        >
          <ModalOverlay />

          <ModalContent mt="0" m="0" bg="white" justifyContent={'start'} className='modal-actionbar'
            position='absolute' right='0' h={'100vh'} pb={{base:'200px',md:'0'}} maxH={{base:'100vh', md:'100vh'}} overflow={{base:'auto'}}
          >
            <ModalCloseButton />
            <Text fontSize={'2xl'} fontWeight={'bold'} px={{base:4, md:8}} pt={8} display={{base:'block',md:'none'}}>
              {selectedTreatment} {selectedTreatment==='Medication Refill' || (selectedTreatment && selectedTreatment.includes('General Consult')) ? '' : 'Treatment'}
            </Text>
            <ActionBar
              treatmentCategory={categoryName}
              treatmentList={treatmentList}
              selectedTreatment={selectedTreatment}
              onProtocolNameChange={handleProtocolNameChange}
            />
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
};