import Cookies from "js-cookie";
import config from "../config";

const defaultHeaders = () => {
  const token = Cookies.get("__session");
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
};

const API_ENDPOINT = config.apiEndpoint;


export const fetchTreatments = async (message) => {

  const guid = localStorage.getItem("guid") || '';

  //console.log("message", message, guid)
  const apiBody = {
    message: message,
    guid: guid
  }

  const treatmentSearchResults = await fetch(`${API_ENDPOINT}/ai/search`, {
    method: "POST",
    headers: defaultHeaders(),
    body: JSON.stringify(apiBody)
  });

  return treatmentSearchResults.json()
};

export const sendCallback = async (transactionId) => {
  console.log('making callback', transactionId);
  
  const callbackResults = await fetch(`${API_ENDPOINT}/calls/transaction`, {
    method: "POST", // Use POST method
    headers: {
      ...defaultHeaders(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ transactionId }) // Send transactionId in the body
  });

  return callbackResults.json();
}
