class ActionProvider {
    constructor(createChatBotMessage, setStateFunc, createClientMessage) {
      this.createChatBotMessage = createChatBotMessage;
      this.setState = setStateFunc;
      this.createClientMessage = createClientMessage;
    }    
  
    displayTreatmentOptions(treatmentOptions) {
        
        // Update the chatbot state with treatment options
        this.setState(state => ({
          ...state,
          treatmentOptions: treatmentOptions
        }));
    
        // Then create a message that triggers the widget
        const message = this.createChatBotMessage("", {
          widget: "treatmentOptionsWidget",
          loading: true,
        });
    
        this.addMessageToState(message);
      }      
    
      addMessageToState(message) {
        this.setState(state => ({
          ...state,
          messages: [...state.messages, message]
        }));
        //console.log(this.messages, "messages")
    }
    

    
}

export default ActionProvider;