import { Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ActionBar } from '../components/ActionBar';
import { Footer } from '../components/Footer';
import { Navbar } from '../components/Navbar';
import { TreatmentDetails } from '../components/TreatmentDetails';
import { WeightLossDetails } from '../components/WeightLossDetails';
import { TreatmentHeader } from '../components/TreatmentHeader';
import { categories } from '../data/_categories.ts';
import { treatmentData } from '../data/_treatments.ts';

export const Treatments = () => {

  const { pathname } = useLocation();
  const [matchingTreatment,setMatchingTreatment] = useState(null);
  const [treatmentObject,setTreatmentObject] = useState([]);
  //console.log("Location",pathname)

  useEffect(() => {
    //console.log('updating')
    let treatmentPath;
    if (pathname.includes("medication-refill")) {
      treatmentPath = "medication-refill";
    } else if (pathname && pathname.includes("general-consult")) {
      treatmentPath = "general-consult";
    } else {
      treatmentPath = pathname.split('/treatments/')[1].split(/[/?#]/)[0];
    }

    const matchedTreatment = treatmentData.find(treatment => treatment.path.toLowerCase() === treatmentPath.toLowerCase());
    //console.log('matchedTreatment',matchedTreatment)
    setMatchingTreatment(matchedTreatment);
  }, [pathname]);

  useEffect(() => {
    if (matchingTreatment) {

      const matchedCategory = categories.find(category => category.title === matchingTreatment.primaryCategory);

      setTreatmentObject({
        treatmentCategory: matchingTreatment.primaryCategory,
        treatmentName: matchingTreatment.name,
        treatmentDescription: matchingTreatment.description,
        protocolName: matchingTreatment.genieMdName,
        symptoms: matchingTreatment.symptoms,
        whatYouNeed: matchingTreatment.whatYouNeed,
        priceRange: matchingTreatment.priceRange,
        commonMedications: matchingTreatment.commonMedications,
        categoryImage: matchedCategory ? matchedCategory.image : null,
      });
      //console.log(matchingTreatment.name)
    }
  }, [matchingTreatment, pathname]);

  

  return (
    treatmentObject &&
    <>
      <Navbar />
      <TreatmentHeader 
        name={treatmentObject.treatmentName}
        category={treatmentObject.treatmentCategory}
        description={treatmentObject.treatmentDescription}
        image={treatmentObject.categoryImage}
      />
      <Flex flexDirection={{ base:'column', md:'row' }} maxW={'1344px'} w={'100%'} margin={'0 auto'} >
        <Flex w={{ base:'100%', md:'60%', lg:'65%', xl:'70%' }} flexDirection={{ base:'column', md:'column' }}>
          {treatmentObject.treatmentName === 'Weight Loss' ?
            <WeightLossDetails
              name={treatmentObject.treatmentName}
              symptoms={treatmentObject.symptoms}
              whatYouNeed={treatmentObject.whatYouNeed}
              commonMedications={treatmentObject.commonMedications}
            />
            :
            <TreatmentDetails
              name={treatmentObject.treatmentName}
              symptoms={treatmentObject.symptoms}
              whatYouNeed={treatmentObject.whatYouNeed}
              commonMedications={treatmentObject.commonMedications}
            />
          }
        </Flex>
        <Flex w={{ base:'100%', md:'40%', lg:'35%', xl:'30%' }}>
          <ActionBar 
            priceRangeParam={treatmentObject.priceRange}
            protocolNameParam={treatmentObject.name}
            selectedTreatment={treatmentObject.protocolName}
          />
        </Flex>
      </Flex>
      <Footer />
    </>
  )
}