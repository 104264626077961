import {
  Button, Flex,
  List,
  ListIcon,
  ListItem,
  Text
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
  
export const BenefitsBlock = ({ onButtonClick }) => {

  const navigate = useNavigate();

  const handleHowItWorksClick = () => {
    navigate('/how-it-works');
  };

  return (
    <>
    <Flex
      as="section"
      background="white"
      py={{ base: 16, md: 20 }}
      px={{ base: 4, md: 8 }}
      justifyContent={'space-between'}
      alignItems={'center'}
      margin={'0 auto'}
      w={'100%'}
    >
      <Flex maxW={'1280px'} justifyContent={'center'} margin={'0 auto'} gap={16} flexDirection={{base:'column',md:'row'}}>
        <Flex flexDirection={'column'} gap="3" maxW={'700px'} w={{base:'100%', md:'55%'}}>
          <Text fontSize={'27px'} fontWeight={'bold'} color={'primaryBlue.default'}>Connect with a healthcare provider through the power of technology.</Text>
          <Text fontSize={'18px'} fontWeight={'500'} >When you know your symptoms, and need prompt treatment, just complete an online healthcare questionnaire or speak to a provider.  Upon provider review, you'll receive a diagnosis, treatment plan, and a prescription as appropriate.</Text>
          <Button variant={'primaryBlueSolid'} size="xl" py={4} px={6} mt={4} maxW={'160px'}
            onClick={onButtonClick}
          >
            Get Started
          </Button>
        </Flex>
        <Flex flexDirection={'column'} alignContent={'center'} h={'100%'} margin={'auto 0'} w={{base:'100%', md:'40%'}}>
          <List spacing={3} bgColor={'primaryBlue.lighten90'} p={5} borderRadius={8} fontSize={18}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="primaryOrange.default" />
              Board-Certified Healthcare Providers
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="primaryOrange.default" />
              No Big Co-pays or Hidden Healthcare Costs
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="primaryOrange.default" />
              No Insurance Needed
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="primaryOrange.default" />
              No Need to Wait Days for Appointments or Hours in Waiting Rooms
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="primaryOrange.default" />
              No Subscription Needed
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="primaryOrange.default" />
              No Hassles; Just Care Now
            </ListItem>
          </List>
        </Flex>    
      </Flex>
    </Flex>
    <Flex
      as="section"
      background={'primaryBlue.default'}
      py={{ base: 8, md: 12 }}
      px={{ base: 8, md: 24 }}
      width="100%"
      justifyContent={'space-between'}
      flexDirection={'column'}
      textAlign={'center'}
      alignItems={'center'}
      gap={5}
    >
      <Text fontSize={'30px'} color={'primaryYellow.default'} fontWeight={'bold'}>HOW IT WORKS</Text>
      <Text fontSize={'20px'} color={'white'} maxWidth={'800px'} margin="0 auto">Symliphy propels you through time to each step, fast as a flash, discussing your health concerns and providing guidance through simple checklists, and virtual visits.</Text>
      <Button variant={'primaryYellowSolid'} size="xl" py={4} px={6} mt={4} maxW={'160px'}
        onClick={handleHowItWorksClick}
      >
        Learn More
      </Button>
    </Flex>

    </>
  )
}