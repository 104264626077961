export const VirtualProcess = [
  {
    title: 'Browse Common SYMPTOMS',
    category: 'Virtual Consultation Process',
    image: '/images/Symliphy_step1.svg',
    steps: [
      'Bookmark our website to quickly return for future healthcare services (recommended)',
      'Review the list of conditions and associated symptoms (Urgent Care, Women\'s Health, Men\'s Health, etc)',
      'Choose your condition',
      'Choose your state to determine treatment options',
      'Choose Virtual Consultation as treatment option'
    ],
    order: 1
  },
  {
    title: 'View Treatment PROTOCOLS',
    category: 'Virtual Consultation Process',
    image: '/images/Symliphy_step2.svg',
    steps:[
      'Create an account or log into your existing account.',
      'For Pediatric Conditions - Parents, please complete the New Patient information for your child, but include your personal contact information.',
      'Complete the online healthcare questionnaire.',
      'If the healthcare questionnaire determines that you do not qualify for virtual care, the Symliphy platform will inform you as such and recommend another treatment path (Live Consultation, etc). You will not be charged for that virtual consultation.',
      'Choose your preferred pharmacy.',
      'Pay for consultation.',
      'The healthcare questionnaire is sent to a licensed healthcare provider in your state for review.',
      'A receipt for the consultation will be sent to the email address in your Symliphy profile.',
      'A healthcare provider creates a treatment plan that may include prescription medication.',
      'In some cases, your healthcare provider may need to obtain more information from you about your case. If so, they will contact you directly via phone or video conference.  Please be on the look out for any calls from unknown phone numbers.'
    ],
    order: 2
  },
  {
    title: 'Prescription sent to PHARMACY',
    category: 'Virtual Consultation Process',
    image: '/images/Symliphy_step3.svg',
    steps:[
      'The healthcare provider electronically sends your prescription to your preferred pharmacy.',
      'Your preferred pharmacy fills the prescription.',
      'Pay for your prescription using your insurance or the Symliphy cash discount card.',
      'Pick up the prescription or have it delivered (if applicable) from your preferred pharmacy.',
      'Feel better faster!'
    ],
    order: 3
  }
];

export const LiveProcess = [
  {
    title: 'Browse Common SYMPTOMS',
    category: 'Virtual Consultation Process',
    image: '/images/Symliphy_step1.svg',
    steps: [
      'Bookmark our website to quickly return for future healthcare services (recommended)',
      'Review the list of conditions and associated symptoms (Urgent Care, Women\'s Health, Men\'s Health, etc)',
      'Choose your condition',
      'Choose your state to determine treatment options',
      'Choose Live Consultation as treatment option'
    ],
    order: 1
  },
  {
    title: 'View Treatment PROTOCOLS',
    category: 'Virtual Consultation Process',
    image: '/images/Symliphy_step2.svg',
    steps:[
      'Create an account or log into your existing account.',
      'For Pediatric Conditions - Parents, please complete the New Patient information for your child, but include your personal contact information.',
      'Complete the online healthcare questionnaire.',
      'Choose your preferred pharmacy.',
      'Pay for consultation.',
      'The healthcare questionnaire is sent to a licensed healthcare provider in your state for review.',
      'A receipt for the consultation will be sent to the email address in your Symliphy profile.',
      'A healthcare provider will contact you directly via phone or video conference.  Please be on the look out for any calls from unknown phone numbers.',
      'A healthcare provider creates a treatment plan that may include prescription medication.'
      
    ],
    order: 2
  },
  {
    title: 'Prescription sent to PHARMACY',
    category: 'Virtual Consultation Process',
    image: '/images/Symliphy_step3.svg',
    steps:[
      'The healthcare provider electronically sends your prescription to your preferred pharmacy.',
      'Your preferred pharmacy fills the prescription.',
      'Pay for your prescription using your insurance or the Symliphy cash discount card.',
      'Pick up the prescription or have it delivered (if applicable) from your preferred pharmacy.',
      'Feel better faster!'
      
    ],
    order: 3
  }
];

export const WeightLossInitialProcess = [
  {
    title: 'Browse Common SYMPTOMS',
    category: 'Virtual Consultation Process',
    steps: [
      'Bookmark our website to quickly return for future healthcare services (recommended).',
      'Choose your state to determine if Weight Loss treatment is available in your state.',
      'The Weight Loss consultation process consists of 2 steps. A Virtual Consultation and Live Consultation.',
      'Choose Virtual Consultation (Step 1) as treatment option to begin.'
    ],
    order: 1
  },
  {
    title: 'View Treatment PROTOCOLS',
    category: 'Virtual Consultation Process',
    steps:[
      'Create an account or log into your existing account.',
      'Complete the free online healthcare questionnaire to determine if you qualify for treatment.',
      'If you qualify, a healthcare provider will provide you with a Metabolic test order.',
      'Pay for Virtual Consultation.',
      'A receipt for the Virtual Consultation will be sent to the email address in your Symliphy profile.',
      'The Metabolic test order will typically be for the following items: CBC, CMP, Hgb A1c, Lipid panel, TSH',
      'Take the Metabolic test order provided by our healthcare provider to your local lab testing facility (Find a Quest Diagnostic location https://www.questhealth.com/find-location)',
      'Pay the lab for the Metabolic test. Cost approx: $80',
      'Once the lab provides you with the results, log into your Symliphy account and upload them into your account.',
      'A licensed healthcare provider in your state will review your case.',
      'A healthcare provider will call you to continue the process and start the Live Consultation (Step 2) from the phone number (925)-283-2818.',
      'Pay for Live Consultation.',
      'A receipt for the Live Consultation will be sent to the email address in your Symliphy profile.',
      'A healthcare provider creates a treatment plan that may include prescription medication.'
    ],
    order: 2
  },
  {
    title: 'Prescription sent to PHARMACY',
    category: 'Virtual Consultation Process',
    steps:[
      'The healthcare provider electronically sends your prescription to a Symliphy partner pharmacy.',
      'The pharmacy fills your prescription.',
      'You pay for your prescription using your insurance or the Symliphy cash discount card.  (NEED MORE INFO FROM PARTNER PHARMCY)',
      'The prescription will be delivered to your home.',
      'You begin your weight loss journey faster!'      
    ],
    order: 3
  }
];

export const WeightLossRefillProcess = [
  {
    title: 'Browse Common SYMPTOMS',
    category: 'Virtual Consultation Process',
    steps: [
      'Download the Symliphy App on your iPhone/Android device or Bookmark our website to quickly return for future healthcare services (recommended).'
    ],
    order: 1
  },
  {
    title: 'View Treatment PROTOCOLS',
    category: 'Virtual Consultation Process',
    steps:[
      'Log into your existing account 7 days before your last dose.',
      'Complete the online healthcare questionnaire.',
      'The healthcare questionnaire is sent to a licensed healthcare provider in your state for review.',
      'If the healthcare questionnaire determines that you no longer qualify for virtual weight loss care, the Symliphy platform will inform you as such and recommend another treatment path (Live Consultation, etc).  You will not be charged for that Virtual Consultation.',
      'Pay for Virtual Consultation.',
      'A receipt for the Virtual Consultation will be sent to the email address in your Symliphy profile.',
      'A healthcare provider reviews the treatment plan and either submits a medication refill to continue or contacts you about recommended changes.'      
    ],
    order: 2
  },
  {
    title: 'Prescription sent to PHARMACY',
    category: 'Virtual Consultation Process',
    steps:[
      'The healthcare provider electronically sends your prescription to a Symliphy partner pharmacy.',
      'The pharmacy fills your prescription.',
      'You pay for your prescription using your insurance or the Symliphy cash discount card.  (NEED MORE INFO FROM PARTNER PHARMCY)',
      'The prescription will be delivered to your home.',
      'You begin your weight loss journey faster!'      
    ],
    order: 3
  }
];

export const WeightLossProcess = [
  {
    category: 'Weight Loss',
    image: '/images/weightlossstep1.png',
    steps: [
      'Pick your state and click on the New or Existing Weight Loss Patient Consultation button.', 
      'Complete the Treatment Protocol – a simple self guided healthcare questionnaire.',
      'At the end of the healthcare questionnaire, if you qualify for treatment, you will be asked to pay for your treatment plan.  This payment includes the healthcare provider consultation with lab order (if applicable), weight loss medication, shipping and handling of the medication to your home.',

    ],
    order: 1
  },
  {
    title: 'For NEW Symliphy Weight Loss Patients Only (Existing Patients Skip This Section)',
    category: 'Weight Loss',
    image: '/images/weightlossstep2.png',
    steps:[
      'Immediately upon the completion of your healthcare provider’s review of your case, a lab order will be sent to Quest Diagnostics. The lab order and subsequent lab test is to ensure the Weight Loss treatment is safe for you.  The lab test will screen for liver, kidney, and thyroid function.',
      'Go to your local Quest Diagnostics location to complete the test. Find a PSC Location | Quest Diagnostics  You MUST complete the lab test for your healthcare provider to prescribe a treatment plan. The typical Quest Diagnostics lab fees range from $60 to $80.',
      'Once Quest completes the lab testing the results will be returned to your healthcare provider within the Symliphy system electronically. No action needs to be taken by you.',
      'Your healthcare provider will review your lab results and reach out to you directly via phone, text, and/or email to complete a “live” consultation either by telephone or Zoom link.',
    ],
    order: 2
  },
  {
    title: 'For EXISTING Symliphy Weight Loss Patients Only',
    category: 'Weight Loss',
    steps:[
      'Your healthcare provider will review your latest healthcare questionnaire and determine if adjustments are needed to your treatment plan.  If so, they will reach out to you directly via phone, text, and/or email to complete a “live” consultation either by telephone or Zoom link.  If no adjustments are needed, they will electronically prescribe additional medication (as described in the next step below).'     
    ],
    order: 3
  },
  {
    category: 'Weight Loss',
    image: '/images/weightlossstep3.png',
    steps:[
      'Your healthcare provider will electronically prescribe the appropriate weight loss medication to our partner compounding pharmacy.',
      'The medication will be shipped directly to your home address and arrive within 4 days.',
      'Take the prescribed medication as directed by your healthcare provider and Start Losing Weight!',
      'To continue your Symliphy weight loss journey, please return to Symliphy monthly and choose the Existing Weight Loss Patient Consultation button to complete steps 1-3 and 8-10.  A monthly healthcare provider consultation is required to continue your treatment plan. We recommend',      
    ],
    order: 4
  }
];