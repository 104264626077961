import {
  Box,
  Button,
  Flex,
  Grid, Image,
  List, ListItem,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext.jsx';

import { categories } from '../data/_categories.ts';

const TreatmentList = ({ title, treatments, onSelect, onCategory, isOpen }) => {
  
  const sortedTreatments = [...treatments].sort((a, b) => {
    // Check if 'General Consult' should be prioritized
    if (a.includes("General Consult")) return -1;
    if (b.includes("General Consult")) return 1;
  
    // Fallback to standard alphabetical sort for other treatments
    return a.localeCompare(b);
  });
  
  //console.log(sortedTreatments);
  const hasTwoColumns = sortedTreatments.length > 10;
  const columnSplitIndex = hasTwoColumns ? Math.ceil(sortedTreatments.length / 2) : sortedTreatments.length;
  const firstColumnTreatments = sortedTreatments.slice(0, columnSplitIndex);
  const secondColumnTreatments = hasTwoColumns ? sortedTreatments.slice(columnSplitIndex) : [];

  

  return (
    <VStack
      spacing={2}
      position="absolute"
      left={0}
      right={0}
      padding={0}
      top={'0%'}
      bg={'primaryYellow.lighten50'}
      zIndex={2}
      shadow="md"
      display={isOpen ? 'block' : 'none'}
      minH={'100%'}
    >
      <Flex fontSize="xl" mt="0" w="100%" justifyContent="center" alignItems="center"
         pt={5} pb={5} onClick={() => onCategory(title)}
      >    
        {title}
      </Flex>
      {title !== 'Weight Loss' &&
        <Grid templateColumns={sortedTreatments.length > 10 ? 'repeat(2, 1fr)' : '1fr'} w="full">
          <List >
            {firstColumnTreatments.map((treatment, index) => (
              <ListItem px={5} key={index} cursor="pointer" onClick={() => onSelect(treatment)}
                _hover={{background:'primaryYellow.default'}} py={2}
              >
                {treatment}
              </ListItem>
            ))}
          </List>
          {sortedTreatments.length > 10 && (
            <List>
              {secondColumnTreatments.map((treatment, index) => (
                <ListItem px={5} key={index} cursor="pointer" onClick={() => onSelect(treatment)}
                  _hover={{background:'primaryYellow.default'}} py={2}
                >
                  {treatment}
                </ListItem>
              ))}
            </List>
          )}
        </Grid>
      }
      {title === 'Weight Loss' ?
      <>
        <Flex fontSize="lg" mt={1} w="100%" justifyContent="center" alignItems="center"
          bgColor={"primaryYellow.default"} py={4} fontWeight={'bold'} bottom={'0px'} position='static'
          _hover={{background:'primaryYellow.lighten10'}}
          onClick={() => onSelect(title)}
        >    
          Start Your Weight Loss Journey
        </Flex>
      </>
      :
        <Flex fontSize="lg" mt={1} w="100%" justifyContent="center" alignItems="center"
          bgColor={"primaryYellow.default"} py={4} fontWeight={'bold'} bottom={'0px'} position='static'
          onClick={() => onCategory(title)}
        >    
          {(title && title.includes('General Consult')) || title === 'Medication Refill' ?
          <>Start Your {title}</>
          :
          <>View All {title}</>
          }
        </Flex>
      }
    </VStack>
  );
};

export const TreatmentsGrid = () => {
  const [openService, setOpenService] = useState(null);
  const [showGrid, setShowGrid] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const { pharmacy } = useUser();

  const handleToggle = (title, isHoverEvent = false) => {
    if (isHoverEvent) {
      // For hover, only open the treatment list, don't close it
      setOpenService(title);
    } else {
      // For click, toggle the treatment list
      setOpenService(openService === title ? null : title);
    }
  };

  const handleSelectTreatment = (treatmentName, serviceName) => {
    //console.log("clicking treatmentName",treatmentName,"serviceName",serviceName)

    // Define a base path to navigate up one level
    console.log("location.pathname",location.pathname)
    let basePath=''
    if (location.pathname.includes('/care')) {
      basePath = "../";
    } else {
      basePath = '';
    }

    if (serviceName === 'Medication Refill') {
      navigate(`${basePath}/medication-refill`);
    } else if (serviceName && serviceName.includes('General Consult')) {
      navigate(`${basePath}/general-consult`);
    } else {
      const formattedTreatment = treatmentName
      .toLowerCase() // Convert to lower case
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-+/g, '-'); // Replace multiple consecutive hyphens with a single hyphen
  
    navigate(`${basePath}/treatments/${formattedTreatment}`);
    }
  };


  const handleServiceClick = (serviceName) => {
    // Find the category object that matches the serviceName
    const category = categories.find(c => c.title === serviceName);

    // Define a base path to navigate up one level
    let basePath=''
    console.log("location.pathname",location.pathname)
    if (location.pathname.includes('/care')) {
      basePath = "../";
    } else {
      basePath = '';
    }
    
  
    // If a matching category is found and it has a urlPath, navigate to it
    if (category && category.urlPath && category.urlPath === 'medication-refill') {
      navigate(`${basePath}/medication-refill`)
    } else if (category && category.urlPath && category.urlPath === 'general-consult') {
      navigate(`${basePath}/general-consult`)
    } else if (category && category.urlPath && category.urlPath === 'weight-loss') {
      navigate(`${basePath}/treatments/weight-loss`)
    } else if (category && category.urlPath) {
      navigate(`${basePath}/category/${category.urlPath}`);
    } else {
      // If no specific urlPath is found for the service, navigate to a general category page
      // This is a fallback option. You might want to handle this case differently.
      navigate(`${basePath}/category/${serviceName.toLowerCase().replace(/\s+/g, '-')}`);
    }
  };
  

  return (
    showGrid ?
    <Flex justifyContent={'center'} py={6} maxW={'1280px'} w={'100%'} margin={'0 auto'} flexDirection={'column'} alignItems={'center'}>
      <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={6} position="relative">
        {categories
          .filter(service => !(service.title === "Weight Loss" && pharmacy === "Giant Eagle"))
          .map((service, index) => (
            <Box
              key={index}
              textAlign="center"
              px={5}
              pt={5}
              pb={3}
              shadow={'symShadow'}
              borderWidth="1px"
              w={{ base: '80vw', md: '30vw', lg: '300px' }}
              maxW={'500px'}
              position="relative"
              onMouseEnter={() => handleToggle(service.title, true)}
              onMouseLeave={() => setOpenService(null)}
              cursor={'pointer'}
            >
              <Flex
                fontSize="xl"
                mt="0"
                w="100%"
                justifyContent="center"
                alignItems="center"
                cursor={service.title === 'Medication Refill' || service.title.includes('General Consult') ? 'pointer' : 'default'}
                onClick={() => handleServiceClick(service.title)}
              >
                {service.title}
              </Flex>
              <Image
                src={service.iconPath}
                boxSize="100px"
                margin="0 auto"
                my={3}
                onClick={() => handleServiceClick(service.title)}
              />
              <Text mt="3">{service.description}</Text>
              <TreatmentList
                title={service.title}
                treatments={service.treatments || []}
                onSelect={handleSelectTreatment}
                onCategory={handleServiceClick}
                isOpen={openService === service.title}
              />
            </Box>
          ))}
      </Grid>

      <Button variant={'primaryYellowSolid'} size="xl" py={4} px={6} mt={10} maxW={'160px'} onClick={() => setShowGrid(false)}>Display As List</Button>
    </Flex>
    :
    <Flex justifyContent={'center'} py={12} maxW={'1280px'} w={'100%'} margin={'0 auto'} flexDirection={'column'} alignItems={'center'}>
      <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={6} position="relative">
        {categories.filter(service => !(service.title === "Weight Loss" && pharmacy === "Giant Eagle")).map((service, index) => (
          <Box key={index} px={5} pt={5} pb={3}
            w={{base:'80vw', md:'30vw', lg:'300px'}} maxW={'500px'}>
            <Text 
              fontSize="xl" 
              fontWeight="bold" 
              mb={3} 
              cursor={service.title === 'Medication Refill' || (service.title && service.title.includes('General Consult')) ? 'pointer' : 'default'} 
              _hover={{ textDecoration: service.title === 'Medication Refill' || (service.title && service.title.includes('General Consult')) ? 'underline' : 'none' }}
              onClick={() => handleServiceClick(service.title)}
            >
              {service.title}
            </Text>
            <VStack align="start">
              {service.treatments && [...service.treatments].sort().map((treatment, idx) => (
                <Text key={idx} pl={4} onClick={() => handleSelectTreatment(treatment, service.title)} cursor="pointer"
                  _hover={{textDecoration:'underline'}}
                >{treatment}</Text>
              ))}
            </VStack>
          </Box>
        ))}
      </Grid>
      <Button variant={'primaryYellowSolid'} size="xl" py={4} px={6} mt={10} maxW={'200px'} onClick={() => setShowGrid(true)}>Display As Categories</Button>
    </Flex>
  
  );
};