import { Navbar } from '../components/Navbar.jsx'
import React from 'react';
import {
  Flex, Heading, Text, OrderedList, UnorderedList, ListItem, Link
} from "@chakra-ui/react";
import { Footer } from '../components/Footer.jsx'

export const PrivacyPolicy = () => {

  return (
    <>
      <Navbar />
      <Flex justifyContent={'center'} py={12} px={5} 
        maxW={'1280px'} w={'100%'} margin={'0 auto'} 
        flexDirection={'column'} alignItems={'start'}
        gap={3} 
      >
        <Heading as="h1" size="xl" mb={6} textAlign={'center'} w={'100%'}>Privacy Policy</Heading>

        <Text fontWeight={'bold'}>Effective Date: January 1, 2024</Text>     

        <Text>DigitalCareForMe, LLC, an Ohio limited liability company d/b/a Symliphy, and/or its subsidiaries ("DC4ME," "we," or "us") own and operate software applications (including mobile applications) and websites (including the website currently located at www.Symliphy.com) that contain or link to this Privacy Policy (such applications and websites are collectively referred to as the "Applications"). Your access and use of the Applications, any part thereof, or anything associated therewith, including their content ("Content"), any products or services provided through the Platform or otherwise by DC4ME, and any affiliated website, software or application owned or operated by DC4ME (collectively, including the Applications and the Content, the "Service") are subject to this Privacy Policy unless specifically stated otherwise. Capitalized terms not otherwise defined in this Privacy Policy have the same meaning as set forth in the DC4ME Terms and Conditions ("Terms and Conditions").</Text>
        <Text>We are committed to respecting the privacy of users of the Service. We created this Privacy Policy ("Privacy Policy") to tell you how DC4ME collects, uses and discloses information in order to provide you with the Service.  By creating, registering, or logging into an account through the Service, or otherwise accessing or using the Service, you are acknowledging the most recent version of this Privacy Policy. If we make any changes to our Privacy Policy, we will post the revised Privacy Policy and update the "Last updated" date of the Privacy Policy.</Text>
        <Text>If you are using the Service on behalf of an individual other than yourself, you represent that you are authorized by such individual to act on such individual's behalf and that such individual acknowledges the practices and policies outlined in this Privacy Policy.</Text>
        <Text>This Privacy Policy, together with the DC4ME Terms and Conditions (“Terms and Conditions”), describes our practices in connection with information that we collect through your use of the Applications.  By downloading, accessing or using the Applications, or providing information to us in connection with the Applications, you agree to the terms and conditions of this Privacy Policy and, in particular, you consent to the processing of your information in the manner described below.</Text>
        <Text>This Privacy Policy does not address the privacy practices of any third party or partner we engage in providing you with services. We are not responsible for the privacy practices of any such third parties, including those to whom we disclose Personal Data in accordance with this Privacy Policy or to whom you submit Personal Data having accessed such third-party application or website via the Applications. We do not endorse any applications or websites which may be linked via the Applications. We may collect and process Personal Data, Non-Personal Data and Health Information (as defined below) in connection with the Applications.</Text>

        <Heading as="h2" size="md" mt={6} textAlign={'start'} w={'100%'}>1. PERSONAL INFORMATION WE MAY COLLECT AND HOW WE MAY USE OR DISCLOSE IT.</Heading>
        <Text>“Personal Data" is data that identifies you. We may collect the following Personal Data from you:</Text>
        <OrderedList pl={3}>
          <ListItem>Name and contact information, such as your name, email address, phone number, and billing/physical addresses.</ListItem>
          <ListItem>Demographic data, such as your gender, date of birth, height, weight, blood type, gender, birthdate, and zip code.</ListItem>
          <ListItem>“Health Information,” which includes data that relates to your physical or mental health history or conditions, to treatment you have received for those conditions, or to payments for those conditions. For example, we may collect information regarding whether you are pregnant, or information describing medications you are taking or otherwise relating to your health, such as details of allergies, conditions, blood pressure, sleeping patterns, and so on.</ListItem>
          <ListItem>Account access information, such as a username or account number in combination with a password, security, or access code, or other credential that allows access to an account.</ListItem>
          <ListItem>Contents of communications.  We may collect the contents of communications that you make via the Service.</ListItem>
          <ListItem>Image and Media data collection: Your media that are collected through our application is used only for the personalization of your profile and for your diagnosis, and is not shared to any third-party vendors. You have full control of your data and can be removed by you at any point of time by writing to dataservices@symliphy.com with the subject line “Image and Media Data Removal.”</ListItem>
        </OrderedList>

        <Text>We may use Personal Data:</Text>
        <OrderedList pl={3}>        
          <ListItem>To send you information regarding the Service.</ListItem>
          <ListItem>To send you details about changes to our Terms and Conditions and to this Privacy Policy and other policies.</ListItem>
          <ListItem>To send to you marketing communications that we believe may be of interest to you, but we will use Health Information for these purposes only if you opt-in to receiving marketing communications.</ListItem>
          <ListItem>To personalize your experience with the Service by presenting products and offers tailored to you, if you opt-in to learning about such products and offers.</ListItem>
          <ListItem>To respond to your inquiries/requests.</ListItem>
          <ListItem>For our internal business purposes, such as for data analysis, to identify usage trends, to enhance the Service and for audits.</ListItem>
          <ListItem>To permit you to send communications to your contacts through the Service(by providing us with third party contact details you agree that you have that person’s consent to share these details).</ListItem>
          <ListItem>To provide services related to your medical and other healthcare appointments, e.g., to send you appointment reminders; to communicate with you and your healthcare providers regarding your appointments; and to facilitate payments for the products and services they provide to you.</ListItem>
          <ListItem>As we believe is necessary or appropriate: (a) as required or permitted under applicable law;(b) to respond to requests from public or government authorities; (c) to enforce our Terms and Conditions; and (d) to protect our rights, privacy, safety or property, or that of our affiliates, you or others.</ListItem>
        </OrderedList>

        <Text>We may disclose your Personal Data:</Text>
        <OrderedList pl={3}>
          <ListItem>To healthcare professionals, as you direct, to assist them in providing healthcare services to you.</ListItem>
          <ListItem>To identify you to contacts to whom you send messages through the Service.</ListItem>
          <ListItem>In the event of any reorganization, merger, sale or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceeding), to relevant third parties.</ListItem>
          <ListItem>As we believe is necessary or appropriate: (a) as required or permitted under applicable law; (b) to respond to requests from public or government authorities; (c) to enforce our Terms and Conditions; and (d) to protect our rights, privacy, safety or property, or that of our affiliates, you or others.</ListItem>
        </OrderedList> 

        <Text>In addition, it may be possible for you to disclose Personal Data through the Service on message boards, forums, profile pages and blogs. Please note that any information and materials that you post or disclose through these services may be publicly available. We recommend that you consider carefully whether you wish to share your Health Information or any other Personal Data when you use the Service.  We do not share your Personally Identifiable Information (PII) with third parties. We are not liable for the security and privacy of any information that you choose to print or email from our application.  Your personal data is retained as long as your account is active. You may close your account or the account of a minor if you are a parent or legal guardian by sending an email to dataservices@symliphy.com with the login email address. We will contact you to verify your identity and subsequently close the account and delete all of the personal data associated with the account.  We will notify you by email and by posting on our website at www.symliphy.com prior to making our app inoperable in the event that our application is banned by the distributor, the government, or recalled by us.</Text>
        
        <Heading as="h2" size="md" mt={6} textAlign={'start'} w={'100%'}>2. NON-PERSONAL INFORMATION WE MAY COLLECT AND HOW WE MAY USE OR DISCLOSE IT</Heading>

        <Text>“Non-Personal Data” is data that does not reveal your identity, for example:</Text>
        <OrderedList pl={3}>
          <ListItem>Server log files;</ListItem>
          <ListItem>Identifiers and device information;</ListItem>
          <ListItem>Geolocation data;</ListItem>
          <ListItem>Usage data</ListItem>
          <ListItem>Information collected through cookies and other technologies;</ListItem>
          <ListItem>Demographic information;</ListItem>
          <ListItem>Health Information and other Personal Data that has been de-identified and can no longer identify you; and</ListItem>
          <ListItem>Browser information.</ListItem>
        </OrderedList>

        <Text>We may collect Non-Personal Data in a number of ways, for example:</Text>
        <OrderedList pl={3}>
          <ListItem>Through the device you use to access the Service: e.g., your operating system name and version, device manufacturer, device model and identifier etc. We may use this data to analyze how the Applications are being used.</ListItem>
          <ListItem>Using cookies: Cookies are small text files which are placed on the device on which you are using the Service. We use cookies to better serve you with more tailored information and facilitate your ongoing access to and use of the Service, as well as for online tracking purposes. You can disable cookies via your browser settings; however, this may mean that some of the features of the Service are no longer available to you. To learn more about cookies, please visit http://www.allaboutcookies.org.</ListItem>
          <ListItem>Through server log files: An Internet Protocol address (“IP Address”) is a numerical label assigned to the device that you are using by your Internet Service Provider. Your IP Address is identified and logged in our server log files whenever you use the Applications, along with the time of the use. We use IP Addresses for purposes such as helping to calculate usage levels of the Service.</ListItem>
          <ListItem>Using web beacons: Web beacons may be used in connection with the Applications and/or Service to track the actions of users, and to measure the success of our marketing campaigns.</ListItem>
          <ListItem>From you: We may collect information from you which you volunteer (e.g., your location).  Unless combined with Personal Data, this information does not personally identify you.</ListItem>
          <ListItem>Location data: Depending on your device and app settings, we collect geolocation data when you use our apps or Service. This information may include precise geolocation data, meaning data derived from a device and that is used to locate you within a circle with a radius of 1,850 feet or less, which is considered a type of sensitive personal information.  We may establish the physical location of your device, for example, using satellite, cell phone tower or WiFi signals. We may use your device’s physical location to provide you with location-based services and content tailored to you. We may also share your device’s physical location, combined with information about what advertisements you viewed and other information we collect, with our marketing partners to enable them to provide you with more personalized content. You may deny us establishing the physical location of your device, however, if you choose to do so, we and/or our marketing partners may not be able to provide you with personalized services and content.</ListItem>
          <ListItem>We have capacity to log your activity on our websites, apps and connected products, including the URL and equivalent information of the website or app from which you came to our website and/or Applications/Services, content you viewed, how long you spent on a page, access times, and other details about your use of and actions on our Applications and Service. In some instances, such usage data may be sensitive personal information.</ListItem>
        </OrderedList>

        <Text>We may use and disclose Non-Personal Data for any purpose. In some instances, we may combine Non-Personal Data with Health Information or other Personal Data. If we combine any Non-Personal Data with Health Information or other Personal Data, the combined information will be treated by us as Health Information or Personal Data, as long as it is so combined.</Text>
        <Text>We infer new information from other data we collect, including using automated means to generate information about your likely preferences or other characteristics (“inferences”). For example, we infer your general geographic location (such as city, state, and country) based on your IP address.</Text>

        <Heading as="h2" size="md" mt={6} textAlign={'start'} w={'100%'}>3. HEALTH INFORMATION WE MAY COLLECT AND HOW WE MAY USE IT</Heading>
        <Text>“Health Information” is data that relates to your physical or mental health history or conditions, to treatment you have received for those conditions, or to payments for those conditions. We treat Health Information as Personal Data, subject to some special rules, that are described above under the heading “Personal Information We May Collect and How We May Use or Disclose It.”  As DC4ME transmits your health information to third parties so you may receive health services, which may fall under the guise of the Health Insurance Portability and Accountability Act (“HIPAA”) as amended by the Health Information Technology for Economic and Clinical Health Act (“HITECH Act”), you will be required to sign or click “accept” on a HIPAA authorization form upon beginning use of the Service.  Should you wish to revoke such authorization, you may complete and submit a <a href="files/HIPAA Revocation FINAL.030124.docx">HIPAA revocation form</a> to DC4ME at hipaarevocation@symliphy.com.</Text>
        <Link href="files/HIPAA Revocation FINAL.030124.docx">Click here to Download HIPAA Revocation Form</Link>

        <Heading as="h2" size="md" mt={6} textAlign={'start'} w={'100%'}>4. WHERE WE STORE YOUR INFORMATION</Heading>
        <Text>The Personal Data and Non-Personal Data we collect from you is stored in the United States. It may be processed by our employees or by one of our carefully selected, third-party service providers. By using the Applications and Service, you agree to this.  We take all reasonable steps to ensure that your Personal Data and Non-Personal Data is processed securely.</Text>

        <Heading as="h2" size="md" mt={6} textAlign={'start'} w={'100%'}>5. ADVERTISERS AND MARKETING</Heading>
        <Text>Third parties may display advertisements on the Applications. These third parties may also place a cookie on your device in the course of you viewing their advertisements. If you would like more information about this practice, please click here.  We will not share your Health Information with advertisers or other third parties. We will use your Health Information for marketing purposes only if you have opted-in to receive marketing communications. If you opt-in, you may opt-out at any time by sending us an e-mail at dataservices@symliphy.com.</Text>

        <Heading as="h2" size="md" mt={6} textAlign={'start'} w={'100%'}>6. JURISDICTION</Heading>
        <Text>The Applications are controlled by us from the United States.  This Privacy Policy, and our collection and subsequent processing of your Personal Data is governed by U.S. law (not by the laws of any country, territory or jurisdiction other than the United States).  We do not represent or warrant that the Applications, or any functionality or feature thereof are appropriate or available for use in any particular jurisdiction. Those who choose to access or use the Applications do so on their own initiative and at their own risk. You are responsible for complying with all local laws, rules and regulations.  By using the Applications and submitting any Health Information or other Personal Data, users from outside the United States acknowledge that the Applications are subject to U.S. law and consent to the transfer of Health Information or other Personal Data to the United States, which may provide a different level of data security than in their country of residence. You hereby waive any claims that may arise under your own national laws, other than US law.</Text>

        <Heading as="h2" size="md" mt={6} textAlign={'start'} w={'100%'}>7. USE BY MINORS.</Heading>
        <Text>The Applications are generally intended for use by individuals who are at least eighteen (18) years of age or such older age as may be required by applicable state laws in the jurisdiction in which an individual utilizes the Service. Individuals who are between the ages of thirteen (13) and eighteen (18) (or such older age of majority) may use the Service only when a parent or guardian is using the Applications on their behalf. The Service is not designed or intended to attract, and is not directed to, children under thirteen (13) years of age. If we obtain actual knowledge that we have collected personal information through the Platform from a person under thirteen (13) years of age, we will use reasonable efforts to refrain from further using such personal information or maintaining it in retrievable form.</Text>
        <Text>Furthermore, if it is discovered that a user is under sixteen (16) years of age, then such user (or user’s parent or legal guardian if under age 13) may at any time request that we remove content or information about the user that is posted on the Applications and/or other pertinent DC4ME materials. Please submit any such request ("Request for Removal of Minor Information") to either of the following:</Text>

        <UnorderedList pl={3}>
          <ListItem>By mail: Symliphy PO BOX 561 Mason, OH 45040, with a subject line of "Removal of Minor Information. If you send by mail, please send by U.S. Certified Mail, Return Receipt Requested to allow for confirmation of mailing, delivery and tracking.</ListItem>
          <ListItem>By email: dataservices@symliphy.com, with a subject line of "Removal of Minor Information"</ListItem>
        </UnorderedList>

        <Text>The foregoing is a description of DC4ME's voluntary practices concerning the collection of personal information through the Service from certain minors, and is not intended to be an admission that DC4ME is subject to the Children's Online Privacy Protection Act, the Federal Trade Commission's Children's Online Privacy Protection Rule(s), or any similar international, federal, state, or local laws, rules, or regulations.</Text>

        <Heading as="h2" size="md" mt={6} textAlign={'start'} w={'100%'}>8. GENERAL</Heading>
        <Text>If any provision of this Privacy Policy is found to be void or unenforceable, such provision shall be severed and all other provisions shall remain in force.  We may change this Privacy Policy at any time. Please take a look at the "Effective Date" at the top of this page to see when this Privacy Policy was last revised. Any changes to this Privacy Policy will become effective when we make the revised Privacy Policy available through the Applications. Your use of the Applications and/or Service, or the submission of any information in connection with the Applications following such changes means that you accept the revised Privacy Policy.  California Civil Code Section 1798.83 permits our customers who are California residents to request certain information regarding the disclosure of Personal Data to third parties for direct marketing purposes. To make such a request, please write to us at Symliphy PO BOX 561 Mason, OH 45040.  If you have any questions in relation to this Privacy Policy please contact DC4ME at dataservices@symliphy.com.</Text>

      </Flex>
      <Footer />
    </>
  )
}