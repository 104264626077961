import { fetchTreatments } from "../helpers/fetch.js";

class MessageParser {
  constructor(actionProvider, state) {
    this.actionProvider = actionProvider;
    this.state = state;
  }

  async parse(message) {
    try {
      // Await the async call to fetchTreatments and get the result directly.
      const data = await fetchTreatments(message);
  
      // Filter the data to only include items where item.score > 0.2
      const filteredData = data.filter(item => item.score > 0.20);
      //console.log('filteredData:', filteredData)
  
      // Assuming 'filteredData' is an array of treatment options
      return this.actionProvider.displayTreatmentOptions(filteredData);
    } catch (error) {
      console.error("Failed to parse message and fetch treatments:", error);
    }
  }
}

export default MessageParser;
