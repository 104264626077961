import {
  Flex,
  Text
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
  
export const IndividualHeaderText = ({ text }) => {

  const navigate = useNavigate();

  const handleHowItWorksClick = () => {
    navigate('/how-it-works');
  };

  return (
    <>
    <Flex
      as="section"
      background="white"
      pt={{ base: 4, md: 8 }}
      px={{ base: 4, md: 8 }}
      justifyContent={'space-between'}
      alignItems={'center'}
      margin={'0 auto'}
      w={'100%'}
    >
      <Flex maxW={'1280px'} justifyContent={'center'} margin={'0 auto'} gap={3} flexDirection={{base:'column',md:'row'}}>
        <Text fontSize={'27px'} fontWeight={'bold'} color={'primaryBlue.default'}>{text}</Text>
      </Flex>
    </Flex>

    </>
  )
}