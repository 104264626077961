export const AsyncStates = [
  "Maine",
  "New Hampshire",
  "Alabama",
  "California",
  "Florida",
  "Georgia",
  "Illinois",
  "Michigan",
  "Montana",
  "Nevada",
  "Ohio",
  "Colorado",
  "Connecticut",
  "Hawaii",
  "Iowa",
  "Maryland",
  "Massachusetts",
  "Nebraska",
  "North Carolina",
  "Pennsylvania",
  "South Dakota",
  "Texas",
  "Wisconsin",
  "Wyoming",
  "North Dakota",
  "Oregon",
  "South Carolina",
  "Virginia",
  "Idaho"
]

export const AllStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

export const WeightLossStates = [
  "Alaska",
  "Arizona",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Michigan",
  "Missouri",
  "Montana",
  "Nebraska",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wyoming"
]