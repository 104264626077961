import { Navbar } from '../components/Navbar.jsx'
import React from 'react';
import {
  Flex, Heading, Text, ListItem, UnorderedList
} from "@chakra-ui/react";
import { Footer } from '../components/Footer.jsx'

export const PatientConsent = () => {

  return (
    <>
      <Navbar />
      <Flex justifyContent={'center'} py={12} px={5} 
        maxW={'1280px'} w={'100%'} margin={'0 auto'} 
        flexDirection={'column'} alignItems={'start'}
        gap={3} 
      >
        <Heading as="h1" size="xl" mb={6} textAlign={'center'} w={'100%'}>Telehealth Patient Consent</Heading>

        <Text fontWeight={'bold'} mb={6}>By clicking “Accept,” you affirmatively demonstrate ascent to the terms and conditions of this Telehealth Patient Consent (the “Consent”) and affirmatively consent to receive telehealth services in the state in which you reside.</Text>     

        <Text fontWeight={'bold'}>What is telehealth?</Text>
        <Text>Telehealth is a way to visit with healthcare providers, such as your doctor or nurse practitioner.</Text>
        <Text>You can talk to your provider from any place, including your home. You don’t go to a clinic or hospital.</Text>

        <Text fontWeight={'bold'}>How do I use telehealth?</Text>
        <UnorderedList pl={3}>
          <ListItem>You talk to your provider by phone, computer, or tablet, or submit questionnaires regarding your symptoms and your doctor diagnoses your condition.</ListItem>
          <ListItem>Sometimes, you use video so you and your provider can see each other.</ListItem>
        </UnorderedList>

        <Text fontWeight={'bold'}>How does telehealth help me?</Text>
        <UnorderedList pl={3}>
          <ListItem>You don’t have to go to a clinic or hospital to see your provider.</ListItem>
          <ListItem>You won’t risk getting sick from other people.</ListItem>
        </UnorderedList>

        <Text fontWeight={'bold'}>Can telehealth be bad for me?</Text>
        <UnorderedList pl={3}>
          <ListItem>You and your provider won’t be in the same room, so it may feel different than an office visit. </ListItem>
          <ListItem>Your provider may make a mistake because they cannot examine you as closely as at an office visit. (We don’t know if mistakes are more common with telehealth visits.)</ListItem>
          <ListItem>Your provider may decide you still need an office visit.</ListItem>
          <ListItem>Technical problems may interrupt or stop your visit before you are done.</ListItem>
        </UnorderedList>

        <Text fontWeight={'bold'}>Will my telehealth visit be private?</Text>
        <UnorderedList pl={3}>
          <ListItem>We will not record visits with your provider or make undue disclosure of questionnaires you submit that are not related to diagnosing and treating your condition.</ListItem>
          <ListItem>If people are close to you, they may hear something you did not want them to know. You should be in a private place, so other people cannot hear you or see your answers.</ListItem>
          <ListItem>Your provider will tell you if someone else from their office can hear or see you.</ListItem>
          <ListItem>We use telehealth technology that is designed to protect your privacy.</ListItem>
          <ListItem>If you use the Internet for telehealth, use a network that is private and secure.</ListItem>
          <ListItem>There is a very small chance that someone could use technology to hear or see your telehealth visit.</ListItem>
        </UnorderedList>

        <Text fontWeight={'bold'}>What if I want an office visit, not a telehealth visit?</Text>
        <Text>All visits are by telehealth.</Text>

        <Text fontWeight={'bold'}>What if I try telehealth and don’t like it?</Text>
        <UnorderedList pl={3}>
          <ListItem>You can stop using telehealth any time, even during a telehealth visit.</ListItem>
          <ListItem>You can still get an office visit if you no longer want a telehealth visit, but you will need to contact your primary care provider outside of our health services to do so.  Should you choose not to use telehealth services, it will be as if you never signed this form.</ListItem>
        </UnorderedList>

        <Text fontWeight={'bold'}>How much does a telehealth visit cost?</Text>
        <UnorderedList pl={3}>
          <ListItem>You will see the full cost of your visit prior to its beginning, including submission of any questionnaires.</ListItem>
          <ListItem>A telehealth visit will not cost any more than an office visit.</ListItem>
          <ListItem>If your provider decides you need an office visit in addition to your telehealth visit, you may have to pay for both visits.</ListItem>
        </UnorderedList>

      </Flex>
      <Footer />
    </>
  )
}