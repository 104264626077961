import { Navbar } from '../components/Navbar.jsx'
import React from 'react';
import {
  Flex, Heading, Text, OrderedList, ListItem
} from "@chakra-ui/react";
import { Footer } from '../components/Footer.jsx'

export const NoticePrivacyPractices = () => {

  return (
    <>
      <Navbar />
      <Flex justifyContent={'center'} py={12} px={5} 
        maxW={'1280px'} w={'100%'} margin={'0 auto'} 
        flexDirection={'column'} alignItems={'start'}
        gap={3} 
      >
        <Heading as="h1" size="xl" mb={6} textAlign={'center'} w={'100%'}>DigitalCareForME, LLC d/b/a Symliphy Notice of Privacy Practices</Heading>
        <Text>Your Health Information and Rights.  Our Responsibilities.</Text>
        <Text>This notice describes how DigitalCareForMe, LLC d/b/a Symliphy may use and disclose your medical information, and how you can access your own health information.  For clarity, DigitalCareForMe, LLC d/b/a Symliphy does not directly provide treatment or store your personal health information (“PHI”); rather, we transmit it to third-party health providers in order to connect you with care.  Please review carefully.</Text>

        <Heading as="h2" size="md" mt={6} textAlign={'start'} w={'100%'}>Your Rights</Heading>
        <Text>Get an electronic or paper copy of your medical record</Text>
        <OrderedList pl={3}>
          <ListItem>You can ask for an electronic or paper copy of your medical record and other health information we have about you.  This will not be on file with us directly; it will be on file with the medical provider we connect you with.  Ask us how to do this.</ListItem>
          <ListItem>Within about 30 days of requesting, we will provide or have your medical provider provide to you, a copy or summary of your health information. We may charge a reasonable, cost-based fee.</ListItem>
          <ListItem>Your medical record may consist of, but not be limited to, questionnaires you have completed regarding symptoms, records of prescriptions you have received, and direct communications between you and the physician treating you.  Regardless, to the extent your medical record is on file with Symliphy, we engage industry standard encryption and other security technology to keep it safe from improper access and/or use.</ListItem>
        </OrderedList>

        <Text>Ask us to correct your medical record</Text>
        <OrderedList pl={3}>
          <ListItem>To the extent any of your health information is on file with us, you can ask us to correct health information about you that you think is incorrect or incomplete.  If something you are requesting to be corrected is not on file with us, we will direct you where to go to get it corrected.  Ask us how to do this.</ListItem>
          <ListItem>We may say “no” to your request, but we'll tell you why in writing within 60 days.</ListItem>
        </OrderedList>

        <Text>Request confidential communications</Text>
        <OrderedList pl={3}>
          <ListItem>You can ask us to contact you in a specific way (for example, home or office phone) or to send correspondence to a different address or phone number.</ListItem>
          <ListItem>We will say “yes” to all reasonable requests.</ListItem>
        </OrderedList>

        <Text>Ask us to limit what we use or share</Text>
        <OrderedList pl={3}>
          <ListItem>You can ask us not to use or share certain health information for treatment, payment, or our operations.</ListItem>
          <ListItem>We are not required to agree to your request, and we may say “no” if it would affect your care.</ListItem>
          <ListItem>Because we only offer services out-of-pocket and do not accept insurance at this time, you can ask us not to share that you are paying out of pocket with your health insurer.</ListItem>
          <ListItem>We will honor this request unless a law requires us to share that information.</ListItem>
        </OrderedList>

        <Text>Get a list of those with whom we've shared information</Text>
        <OrderedList pl={3}>
          <ListItem>You can ask for a list (accounting) of the times we've shared your health information for six years prior to the date you ask, with whom we shared it, and why.</ListItem>
          <ListItem>We will include all the disclosures except for those about treatment, payment, and health care operations, and certain other disclosures (such as any you asked us to make).  We’ll provide one accounting a year for free but will charge a reasonable, cost-based fee if you ask for another one within 12 months.</ListItem>
        </OrderedList>

        <Text>Get a copy of this privacy notice</Text>
        <OrderedList pl={3}>
          <ListItem>You can ask for a paper copy of this notice at any time, even if you have agreed to receive the notice electronically.  We will provide you with a paper copy promptly.</ListItem>
        </OrderedList>

        <Text>Choose someone to act for you</Text>
        <OrderedList pl={3}>
          <ListItem>If you have given someone medical power of attorney or if someone is your legal guardian, that person can exercise your rights and make choices about your health information.</ListItem>
          <ListItem>We will make sure the person has the authority and can act for you before we take any action.</ListItem>
        </OrderedList>

        <Text>File a complaint if you feel your rights are violated</Text>
        <OrderedList pl={3}>
          <ListItem>You can complain if you feel we have violated your rights by contacting us.</ListItem>
          <ListItem>You can file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1-877-696-6775, or visiting www.hhs.gov/ocr/privacy/hipaa/complaints/.</ListItem>
          <ListItem>We will not retaliate against you for filing a complaint.</ListItem>
        </OrderedList>

        <Heading as="h2" size="md" mt={6} textAlign={'start'} w={'100%'}>Your Choices</Heading>
        <Text>For certain health information, you can tell us your choices about what we share.  If you have a clear preference for how we share your information in the situations described below, talk to us.  Tell us what you want us to do, and we will follow your instructions.</Text>
        <Text>In these cases, you have both the right and choice to tell us to:</Text>
        <OrderedList pl={3}>
          <ListItem>Share information with your family, close friends, or others involved in your care</ListItem>
          <ListItem>Share information in a disaster relief situation</ListItem>
          <ListItem>Include your information in a hospital directory</ListItem>
          <ListItem>If you are not able to tell us your preference, for example if you are unconscious, we may go ahead and share your information if we believe it is in your best interest.  We may also share your information when needed to lessen a serious and imminent threat to health or safety.</ListItem>
        </OrderedList>
        <Text>In these cases we never share your information unless you give us written permission:</Text>
        <OrderedList pl={3}>
          <ListItem>Marketing purposes</ListItem>
          <ListItem>Sale of your information</ListItem>
        </OrderedList>
        <Text>In the case of fundraising:</Text>
        <OrderedList pl={3}>
          <ListItem>We may contact you for fundraising efforts, but you can tell us not to contact you again.</ListItem>
        </OrderedList>

        <Heading as="h2" size="md" mt={6} textAlign={'start'} w={'100%'}>Whom to contact?</Heading>
        <Text>For all inquiries related to privacy, your health information, and all other matters stemming from your use of Symliphy, please contact: Support@symliphy.com.</Text>

      </Flex>
      <Footer />
    </>
  )
}