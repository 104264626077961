import { extendTheme } from '@chakra-ui/react';

// https://www.color-hex.com/color/006699#shades-tints

const theme = extendTheme({
  components: {
    Button: {
      variants: {
        'primaryOrangeSolid': {
          bg: '#F26739',
          color: 'white',
          _hover: {
            bg: '#c1522d'
          }
        },
        'primaryOrangeOutline': {
          bg: 'white',
          color: '#F26739',
          border: 'solid 1px #F26739',
          _hover: {
            bg: '#fdefeb',
          }
        },
        'primaryBlueSolid': {
          bg: '#006699',
          color: 'white',
          _hover: {
            bg: '#00517a'
          }
        },
        'primaryBlueOutline': {
          bg: 'white',
          color: '#006699',
          border: 'solid 1px #006699',
          _hover: {
            bg: '#e5eff4',
          }
        },
        'primaryYellowSolid': {
          bg: '#FFCC33',
          color: 'black',
          _hover: {
            bg: '#cca328'
          }
        },
        'primaryYellowOutline': {
          bg: 'white',
          color: '#FFCC33',
          border: 'solid 1px #FFCC33',
          _hover: {
            bg: '#fff9ea',
          }
        },
      }
    }
  },
  fonts: {
    heading: `'Quicksand', sans-serif`,
    body: `'Quicksand', sans-serif`,
  },
  colors: {
    primaryBlue: {
      default: '#006699',
      darken0: "#006699",
      darken10: "#005b89",
      darken20: "#00517a",
      darken30: "#00476b",
      darken40: "#003d5b",
      darken50: "#00334c",
      darken60: "#00283d",
      darken70: "#001e2d",
      darken80: "#00141e",
      darken90: "#000a0f",
      darken100: "#000000",
      lighten0: "#006699",
      lighten10: "#1975a3",
      lighten20: "#3284ad",
      lighten30: "#4c93b7",
      lighten40: "#66a3c1",
      lighten50: "#7fb2cc",
      lighten60: "#99c1d6",
      lighten70: "#b2d1e0",
      lighten80: "#cce0ea",
      lighten90: "#e5eff4",
      lighten100: "#ffffff",
    },
    primaryOrange: {
      default: '#F26739',
      darken0: "#f26739",
      darken10: "#d95c33",
      darken20: "#c1522d",
      darken30: "#a94827",
      darken40: "#913d22",
      darken50: "#79331c",
      darken60: "#602916",
      darken70: "#481e11",
      darken80: "#30140b",
      darken90: "#180a05",
      darken100: "#000000",
      lighten0: "#f26739",
      lighten10: "#f3764c",
      lighten20: "#f48560",
      lighten30: "#f59474",
      lighten40: "#f7a388",
      lighten50: "#f8b39c",
      lighten60: "#f9c2af",
      lighten70: "#fbd1c3",
      lighten80: "#fce0d7",
      lighten90: "#fdefeb",
      lighten100: "#ffffff",
    },
    primaryYellow: {
      default: '#FFCC33',
      darken0: "#ffcc33",
      darken10: "#e5b72d",
      darken20: "#cca328",
      darken30: "#b28e23",
      darken40: "#997a1e",
      darken50: "#7f6619",
      darken60: "#665114",
      darken70: "#4c3d0f",
      darken80: "#33280a",
      darken90: "#191405",
      darken100: "#000000",
      lighten0: "#ffcc33",
      lighten10: "#ffd147",
      lighten20: "#ffd65b",
      lighten30: "#ffdb70",
      lighten40: "#ffe084",
      lighten50: "#ffe599",
      lighten60: "#ffeaad",
      lighten70: "#ffefc1",
      lighten80: "#fff4d6",
      lighten90: "#fff9ea",
      lighten100: "#ffffff",
    },
    darkGray: {
      default: '#666766',
      darken0: "#666766",
      darken10: "#5b5c5b",
      darken20: "#515251",
      darken30: "#474847",
      darken40: "#3d3d3d",
      darken50: "#333333",
      darken60: "#282928",
      darken70: "#1e1e1e",
      darken80: "#141414",
      darken90: "#0a0a0a",
      darken100: "#000000",
      lighten0: "#666766",
      lighten10: "#757675",
      lighten20: "#848584",
      lighten30: "#939493",
      lighten40: "#a3a3a3",
      lighten50: "#b2b3b2",
      lighten60: "#c1c2c1",
      lighten70: "#d1d1d1",
      lighten80: "#e0e0e0",
      lighten90: "#efefef",
      lighten100: "#ffffff",
    },
    lightGray: {
      default: '#999999',
      darken0: "#999999",
      darken10: "#898989",
      darken20: "#7a7a7a",
      darken30: "#6b6b6b",
      darken40: "#5b5b5b",
      darken50: "#4c4c4c",
      darken60: "#3d3d3d",
      darken70: "#2d2d2d",
      darken80: "#1e1e1e",
      darken90: "#0f0f0f",
      darken100: "#000000",
      lighten0: "#999999",
      lighten10: "#a3a3a3",
      lighten20: "#adadad",
      lighten30: "#b7b7b7",
      lighten40: "#c1c1c1",
      lighten50: "#cccccc",
      lighten60: "#d6d6d6",
      lighten70: "#e0e0e0",
      lighten80: "#eaeaea",
      lighten90: "#f4f4f4",
      lighten100: "#ffffff",
    },
    secondaryPurple: {
      default: '#333366',
      darken0: "#333366",
      darken10: "#2d2d5b",
      darken20: "#282851",
      darken30: "#232347",
      darken40: "#1e1e3d",
      darken50: "#191933",
      darken60: "#141428",
      darken70: "#0f0f1e",
      darken80: "#0a0a14",
      darken90: "#05050a",
      darken100: "#000000",        
      lighten0: "#333366",
      lighten10: "#474775",
      lighten20: "#5b5b84",
      lighten30: "#707093",
      lighten40: "#8484a3",
      lighten50: "#9999b2",
      lighten60: "#adadc1",
      lighten70: "#c1c1d1",
      lighten80: "#d6d6e0",
      lighten90: "#eaeaef",
      lighten100: "#ffffff",
    },
    secondaryBlue: {
      default: '#3399CC',
      darken0: "#3399cc",
      darken10: "#2d89b7",
      darken20: "#287aa3",
      darken30: "#236b8e",
      darken40: "#1e5b7a",
      darken50: "#194c66",
      darken60: "#143d51",
      darken70: "#0f2d3d",
      darken80: "#0a1e28",
      darken90: "#050f14",
      darken100: "#000000",
      lighten0: "#3399cc",
      lighten10: "#47a3d1",
      lighten20: "#5badd6",
      lighten30: "#70b7db",
      lighten40: "#84c1e0",
      lighten50: "#99cce5",
      lighten60: "#add6ea",
      lighten70: "#c1e0ef",
      lighten80: "#d6eaf4",
      lighten90: "#eaf4f9",
      lighten100: "#ffffff",
      opacity30: '#3399CC50',
      opacity50: '#3399CC80',
      opacity80: '#3399CCCC'
    }
  },
  shadows: {
    symShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
    symShadowDarker: "0px 2px 20px rgba(0, 0, 0, 0.2)",
  },
  // ... other theme customizations ...
});

export default theme;
