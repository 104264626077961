import {
  Box,
  Button,
  Flex, Image,
  ListItem, OrderedList,
  Stack, Text,
  UnorderedList
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

export const WeightLossDetails = ({name, symptoms, whatYouNeed, commonMedications, removeMargin=false}) => {

  // Mapping of short names to longer descriptions
  const whatYouNeedDescriptions = {
    'Meds': 'Current medication list (Example: Lipitor, 10mg, daily at night)',
    'Allergies': 'Medication and food allergies (Example: penicillin, peanuts)',
    'Conditions': 'Medical conditions (Example: High blood pressure, diabetic)',
    'Picture Condition': 'Picture of your condition',
    'BP Reading': 'Blood pressure reading',
    'PhotoOfTest': 'Picture of at home test results',
    'Lab Result': 'Any recent lab results',
    'Rx Picture': 'Picture of your prescription',
    'Thermometer': 'Thermometer to take temperature',
    'PCP': 'Primary Care Physician Information - your doctor’s name and telephone number',
    'Vitals': 'Recent blood pressure, pulse rate, and temperature readings',
  };

  // Function to get the long description
  const getLongDescription = (shortName) => {
    return whatYouNeedDescriptions[shortName] || shortName; // Fallback to shortName if no mapping is found
  }

  console.log("symptoms",symptoms,"name",name)
  const { pathname } = useLocation();
  console.log(pathname)

  return (
    <>
      <Flex
        as="section"
        background="white"
        pt={{ base: 6, md: 8 }}
        pb={removeMargin ? 0 : { base: 2, md: 8 }}
        mx={removeMargin ? 0 : { base: 4, md: 8 }}
        justifyContent={'start'}
      >
        <Flex>
          <Stack spacing="3" w={'100%'}>

            <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'}>
              Premium Weight Loss Program
            </Text>
            <Text fontSize={{ base: 'md', md: 'lg' }} py={0}>
              Our <Text as="span" color="primaryBlue.default" fontWeight="bold">PREMIUM</Text> weight loss treatment program is designed as a high touch telehealth solution to assist you on your weight loss journey. Our healthcare providers work with each patient to determine the best approach for their weight loss goals. 
            </Text>

            <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'}>
              Premium Features:
            </Text>
            <UnorderedList fontSize={{ base: 'md', md: 'lg' }} pl={5}>
              <ListItem>Board Certified Physicians providing care</ListItem>
              <ListItem>Safety laboratory testing required through Quest</ListItem> 
              <ListItem>Compounded GLP-1 Medication with B-12</ListItem>
              <ListItem>Compounding Pharmacy is compliant with USP Chapters &#9001;795&#9002; and &#9001;797&#9002;</ListItem> 
              <ListItem>Prefilled Syringes to prevent patient dosing errors</ListItem> 
              <ListItem>Everything you need to administer the medication</ListItem> 
              <ListItem>Vitamin B-12 added to reduce common side effects of nausea and tiredness</ListItem> 
            </UnorderedList>
            <Text fontSize={{ base: 'md', md: 'lg' }} py={0}>
              Regardless of the status of your journey - whether you’re just beginning, searching for a solution that actually works, or looking to maintain your weight - we can design an effective treatment plan for you!
            </Text>
            <Text fontSize={{ base: 'md', md: 'lg' }} py={0}>
              Whether you’re a new or existing patient, your Symliphy weight loss journey will consist of 3 simple steps:
            </Text>
            <Image src="../images/WeightLossHowItWorks.png" w={'600px'} maxW={'100%'} pr={5} py={5}></Image>

            <Flex w={'100%'} flexDirection={'column'} mt={1}>
              <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pb={3}>
                Prescription Medication for Weight Loss: Semaglutide
              </Text>
              <Box fontSize={{ base: 'md', md: 'lg' }}>
                <Text pb={3}>Semaglutide is in a class of medications called incretin mimetics. It helps the pancreas to release the right amount of insulin when blood sugar levels are high. It is a similar molecule to what our bodies make, called a GLP-I analog. It also targets areas in the brain that regulates appetite and food intake. Semaglutide improves body composition by helping patients lose weight and reduce excess fat, including stubborn belly fat.</Text>
                <Text pb={3}>Several laboratory studies have demonstrated that Semaglutide can achieve a greater degree of weight loss compared to other treatments.</Text>
                <Text pb={3}>Semaglutide is the active ingredient in weight loss drugs like Ozempic® and Wegovy®.  Our compounded semaglutide prescription uses the same active ingredient, but is custom-formulated with Vitamin B-12, which may help reduce common side effects such as nausea and tiredness*</Text>
              </Box>
              <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pb={3}>
                Some of Semaglutide's notable benefits include:
              </Text>
              <UnorderedList fontSize={{ base: 'md', md: 'lg' }} pl={5}>
                <ListItem>Leads to a decrease in BMI*</ListItem>
                <ListItem>Not addictive; non-habit-forming medication</ListItem>
                <ListItem>Generally well-received by patients</ListItem>
                <ListItem>Most patients experience minimal or no side effects</ListItem>
                <ListItem>Offers a safe and effective treatment option</ListItem>
                <ListItem>Slows down digestion</ListItem>
                <ListItem>Decreases appetite and cravings</ListItem>
                <ListItem>Rapidly induces feelings of fullness</ListItem>
                <ListItem>Achieves substantial weight loss*</ListItem>
              </UnorderedList>
              <Text pt={3} fontStyle={'italic'}>* Not guaranteed for all patients.</Text>
            </Flex>

            <Flex w={'100%'} flexDirection={'column'} mt={1}>
              <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pb={3} pt={4}>
                How Does Semaglutide Work? 
              </Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} py={0}>
                Semaglutide delays how quickly our stomachs digest food, leading to a feeling of fullness and satisfaction with smaller meal sizes. The medication slows intestinal motility so you will feel fuller longer after meals. It lowers blood sugars, in part by reducing the production of sugar in the liver and stimulates insulin secretion by the pancreas.
              </Text>
              <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pb={3} pt={4}>
                How Is Compounded Semaglutide Prescribed?
              </Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} py={0}>
                Semaglutide is injected once a week. Usually prescribed as once a week dosage. The dosage is based on your medical condition and response to treatment. To reduce your risk of side effects, your doctor may direct you to start this medication at a low dose and gradually increase your dose, with the following dose pattern: 0.25mg SQ weekly for I month, 0.5mg SQ weekly for 1 month, 1 mg SQ weekly for 1 month, 1.75mg weekly for 1 month then 2.5mg weekly thereafter. This medication may be used with or without meals. (SQ = Subcutaneous)
              </Text>
              <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pb={3} pt={4}>
                How do I Inject Semaglutide?
              </Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} py={3}>
                Clean the area you want to inject with an alcohol wipe and allow the skin to dry. Your abdomen (at least 2 inches away from your belly button), thigh (front or outer thigh), or upper arm are all good injection sites. But choose a spot that is at least a finger's width away from the last injection site. Change where you give the injection each time. You can inject in the same area of your body each time-just make sure it's not the same exact spot every time. Moving the injection spot is very important to minimize tissue damage to the area.
              </Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} py={3}>Hold the syringe with the needle facing toward the ceiling, push the plunger slightly until a drop of liquid forms at the end of needle.</Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} py={3}>Pinch and hold the skin that you will inject.</Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} py={3}>Holding the syringe directly at the pinched skin, quickly push the needle through the skin into the fat tissue, making sure to insert the entire length of the needle. Then, slowly push the plunger to inject all the medication.</Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} py={3}>Pull the needle out and clean the skin with an alcohol wipe. It's normal to see a drop of blood at the injection spot. Press gently on the site for a few seconds for the bleeding to stop.</Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} py={3}>
                Discarding the syringe: Discard the syringe in the sharps container or a heavy plastic container with a tight-fitting lid.
              </Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} py={0}>
                Do not throw away the syringe in your household trash.
              </Text>
              <Text fontSize={{ base: 'xl', md: '2xl' }} pt={8} fontWeight={'bold'}>
                Important Safety Information
              </Text>
              <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pb={3} pt={4}>
                What are the side effects of Semaglutide?
              </Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} pb={3}>
                Side effects include swelling/redness/itching at the injection site, tiredness, nausea, vomiting, diarrhea, or constipation can occur. Nausea usually lessens as you continue to use Semaglutide. If any of these effects last or get worse, tell your doctor or pharmacist promptly. An allergic reaction is rare but get medical help immediately if you notice rash, itching, swelling (especially of the face/tongue/throat), severe dizziness or trouble breathing. The risk of serious side effects increases in patients with hypoglycemia, kidney problems, and risk of allergic reactions. Note: The 1mg/mL of Cyanocobalamin (B-12) added to reduce nausea side effects. This is not a complete list of side effects, contact your doctor or pharmacist if you experience any abnormal effects of this medication.
              </Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} py={0}>
                <b>Warning:</b> There is a risk of Thyroid C—Cell tumors. If you or any family members have been diagnosed with Multiple Endocrine Neoplasia Syndrome Type 2 or Medullary thyroid cancer you should not take Semaglutide. If you have ever had pancreatitis consult with your medical provider as using Semaglutide can increase the risk of developing pancreatitis.
              </Text>
            </Flex>
            
            <Flex w={'100%'} flexDirection={'column'} mt={4}>
              <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pb={3}>
                Risk Free To Get Started
              </Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} pt={1}>
                If the online healthcare questionnaire for Virtual or Live Consultations indicates that you do not qualify for treatment, you will NOT incur any charges.
              </Text>
              <Button variant={'primaryBlueOutline'} size="lg" my={5} maxW={'350px'}
                onClick={() => window.open('/how-it-works?tab=2', '_blank')}
              >
                How It Works
              </Button>
            </Flex>  

            {/* What You Need Data */}
            {whatYouNeed && whatYouNeed.length > 0 &&
              <Flex w={'100%'} flexDirection={'column'}>
                <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pb={3}>
                  What You Need
                </Text>
                <Box fontSize={{ base: 'md', md: 'lg' }}>
                  <UnorderedList pl={5}>
                    {whatYouNeed.map((item,index) => (
                      <ListItem key={index}>
                        <Text as="span">{getLongDescription(item)}</Text>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
                <Text fontSize={{ base: 'md', md: 'md' }} fontWeight={'bold'} color={'primaryOrange.default'} pt={3}>
                  Missing any of these items? Don't worry! Just bring what you have and your provider can help you with the rest.
                </Text>
                <Button variant={'primaryBlueOutline'} size="lg" my={5} maxW={'350px'}
                onClick={() => window.open('/faqs?section=weight-loss', '_blank')}
                >
                  Frequently Asked Questions
                </Button>
              </Flex>
            }
            {/* END What You Need Data */}
          </Stack>
        </Flex>
      </Flex>
    </>
  )
}