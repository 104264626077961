import {
  Box, Button,
  Divider,
  Flex,
  Icon,
  Img, Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid, Stack, Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import React from 'react';
import { FiBell, FiMessageCircle, FiRefreshCw, FiSearch, FiUser } from 'react-icons/fi';
import { TbPrescription } from "react-icons/tb";
import { useLocation, useNavigate } from 'react-router-dom';
import { useChatbot } from '../contexts/ChatbotContext';
import logoImage from '../images/Symliphy_Logos_WEB_1024 x 500/Symliphy_Logo_2ColorOnDrk_1024x500.png';
import TrustBox from './TrustBox';

export const links = [
  {
    title: 'Symliphy',
    links: [
      { label: 'How It Works', href: '/how-it-works' },
      { label: 'Our Story', href: '/about-us' },
      { label: 'Our Promise', href: '/brand-promise' },      
      { label: 'FAQs', href: '/faqs' },
    ],
  },
  {
    title: 'Treatment Categories',
    links: [
      { label: 'Urgent Care', href: '/category/urgent-care' },
      { label: 'Women\'s Health', href: '/category/womens-health' },
      { label: 'Men\'s Health', href: '/category/mens-health' },
      { label: 'Pediatric Health', href: '/category/pediatric-health' },
      { label: 'Allergies', href: '/category/allergies' },
    ],
  },
  {
    title: '',
    links: [
      { label: 'Dermatology', href: '/category/dermatology' },
      { label: 'Optometry', href: '/category/optometry' },
      { label: 'Mental Wellness', href: '/category/mental-wellness' },
      { label: 'Chronic Conditions', href: '/category/chronic-conditions' },
    ],
  },
  {
    title: 'Privacy and Terms',
    links: [
      { label: 'Terms of Service', href: '/terms' },
      { label: 'Privacy Policy', href: '/privacy' },
      { label: 'Notice of Privacy Practices', href: '/notice-of-privacy-practices' },
      { label: 'Patient Informed Consent', href: '/patient-informed-consent' },
      { label: 'Provider Licensure Information', href: '/provider-licensure-information' },
      { label: 'HIPAA Authorization', href: '/hipaa-authorization' },
    ],
  },
  {
    title: 'Savings',
    links: [
      { label: 'Symliphy Savings Card', href: '/savings-card' },
      { label: 'Prescription Price Lookup', href: 'https://www1.singlecare.com/symliphy' },
    ],
  },
  {
    title: 'All Services',
    links: [
      { label: 'See All Services', href: '/care' },
      { label: 'General Consult', href: '/general-consult' },
      { label: 'Medication Refill', href: '/medication-refill' },
    ],
  },
]

export const mobileLinks = [
  {
    title: 'Symliphy',
    links: [
      { label: 'How It Works', href: '/how-it-works' },
      { label: 'Our Story', href: '/about-us' },
      { label: 'Our Promise', href: '/brand-promise' },      
      { label: 'FAQs', href: '/faqs' },
    ],
  },
  {
    title: 'Treatment Categories',
    links: [
      { label: 'Urgent Care', href: '/category/urgent-care' },
      { label: 'Women\'s Health', href: '/category/womens-health' },
      { label: 'Men\'s Health', href: '/category/mens-health' },
      { label: 'Pediatric Health', href: '/category/pediatric-health' },
      { label: 'Allergies', href: '/category/allergies' },
      { label: 'Dermatology', href: '/category/dermatology' },
      { label: 'Optometry', href: '/category/optometry' },
      { label: 'Mental Wellness', href: '/category/mental-wellness' },
      { label: 'Chronic Conditions', href: '/category/chronic-conditions' },
    ],
  },
  {
    title: 'Privacy and Terms',
    links: [
      { label: 'Terms of Service', href: '/terms' },
      { label: 'Privacy Policy', href: '/privacy' },
      { label: 'Notice of Privacy Practices', href: '/notice-of-privacy-practices' },
      { label: 'Patient Informed Consent', href: '/patient-informed-consent' },
      { label: 'Provider Licensure Information', href: '/provider-licensure-information' },
      { label: 'HIPAA Authorization', href: '/hipaa-authorization' },
    ],
  },
  {
    title: 'Savings',
    links: [
      { label: 'Symliphy Savings Card', href: '/savings-card' },
      { label: 'Prescription Price Lookup', href: 'https://www1.singlecare.com/symliphy' },
    ],
  },
  {
    title: 'All Services',
    links: [
      { label: 'See All Services', href: '/care' },
      { label: 'General Consult', href: '/general-consult' },
      { label: 'Medication Refill', href: '/medication-refill' },
    ],
  },
]

export const Footer = () => {

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [email, setEmail] = React.useState('');
  const toast = useToast();

  const { toggleBot } = useChatbot();

  const { isOpen: isOpenPatientProfile, onOpen: onOpenPatientProfile, onClose: onClosePatientProfile } = useDisclosure(); // Patient Profile Modal

  const handleMyAccountClick = () => {
    onOpenPatientProfile();
  };

  const handlePatientProfile = () => {
    window.open('https://app.symliphy.com/apps/rpm/#/101365/auth/login', '_blank');
  };

  const handleFindTreatmentClick = () => {
    //navigate('/?show=treatments');
    navigate('/care')
    onClosePatientProfile();
  };
  const handleStartGeneralConsultClick = () => {
    navigate('/general-consult');
    onClosePatientProfile();
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    const portalId = '45215429';
    const formId = 'b3c98962-2f5d-432d-973d-c50b59a546cf';
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    const formData = {
      fields: [
        {
          name: 'email',
          value: email,
        },
      ],
      // Optionally include context about the form or page
      // context: {
      //   pageUri: window.location.href,
      //   pageName: document.title,
      // },
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast({
          title: 'Subscription successful.',
          description: 'You have been subscribed to our newsletter.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        setEmail(''); // Clear the input field
      } else {
        toast({
          title: 'Subscription failed.',
          description: 'There was an issue with your subscription. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      console.error('Submission error:', error);
      toast({
        title: 'Error occurred.',
        description: 'An error occurred while submitting your request. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
  <Flex backgroundColor={'primaryBlue.darken20'} as="footer" role="contentinfo" flexDirection='column'
    justifyContent={'center'} alignItems={'center'} color="white" className='footer print-hide'>
      <Flex bgColor={"white"} width={'100vw'} height="70px" 
        display={{ base: "flex", md: "none" }} position="fixed" 
        bottom="0" left="0" zIndex="500" justifyContent={'space-between'} 
        px={0} alignItems={'center'} boxShadow={'symShadowDarker'}
      >

        {/* Icon 3: Add */}
        <Box textAlign="center" flex={1} color={pathname==='/' ? 'primaryOrange.default' : 'primaryBlue.darken50'}
          onClick={() => toggleBot()}
        >
          <Icon as={FiSearch} boxSize={6} />
          <Text fontSize="xs">Find Care</Text>
        </Box>

        {/* Icon 2: Search */}
        <Box textAlign="center" flex={1} color={pathname==='/general-consult' ? 'primaryOrange.default' : 'primaryBlue.darken50'}
          onClick={() => navigate('/general-consult')}
        >
          <Icon as={FiMessageCircle} boxSize={6} />
          <Text fontSize="xs">Consultation</Text>
        </Box>

        {/* Icon 1: Home */}
        <Box textAlign="center" flex={1} color={pathname==='/savings' ? 'primaryOrange.default' : 'primaryBlue.darken50'}
          onClick={() => {window.open('/savings','_blank')}}
        >
          <Icon as={TbPrescription} boxSize={6} />
          <Text fontSize="xs">Savings</Text>
        </Box>

        {/* Icon 4: Notifications */}
        <Box textAlign="center" flex={1} color={pathname==='/medication-refill' ? 'primaryOrange.default' : 'primaryBlue.darken50'}
          onClick={() => navigate('/medication-refill')}
        >
          <Icon as={FiRefreshCw} boxSize={6} />
          <Text fontSize="xs">Med Refill</Text>
        </Box>

        {/* Icon 5: Profile */}
        <Box textAlign="center" flex={1} color={'primaryBlue.darken50'}
          onClick={handleMyAccountClick}
        >
          <Icon as={FiUser} boxSize={6} />
          <Text fontSize="xs">My Account</Text>
        </Box>

      </Flex>
    <Flex
      gap={{ base: '12', md: '8' }}
      direction={{ base: 'column-reverse', lg: 'row' }}
      py={{ base: '6', md: '16' }} px={4}
      justify="space-between"
      maxW={"1280px"} width="100%"
    >
      <SimpleGrid columns={{ base: 1, md: 3 }} spacingX="8px" spacingY="36px" width={{ base: 'full', lg: 'auto' }} display={{base:'none',md:'grid'}}>
        {links.map((group, idx) => (
          <Stack key={idx} spacing="4" minW={{ lg: '60' }}>
            <Text fontSize="sm" fontWeight="semibold" color="fg.subtle">
              {group.title === '' ? '\u00A0' : group.title}
            </Text>
            <Stack spacing="3" shouldWrapChildren>
              {group.links.map((link, idx) => (
                <Button key={idx} as="a" variant="text" colorScheme="gray" href={link.href} target={link.label === 'Prescription Price Lookup' ? '_blank' : '_self'}>
                  {link.label}
                </Button>
              ))}
            </Stack>
          </Stack>
        ))}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacingX="8px" spacingY="36px" width={{ base: 'full', lg: 'auto' }} display={{base:'grid',md:'none'}}>
        {mobileLinks.map((group, idx) => (
          <Stack key={idx} spacing="4" minW={{ lg: '60' }}>
            <Text fontSize="sm" fontWeight="semibold" color="fg.subtle">
              {group.title === '' ? '\u00A0' : group.title}
            </Text>
            <Stack spacing="3" shouldWrapChildren>
              {group.links.map((link, idx) => (
                <Button key={idx} as="a" variant="text" colorScheme="gray" href={link.href}>
                  {link.label}
                </Button>
              ))}
            </Stack>
          </Stack>
        ))}
      </SimpleGrid>
      <form onSubmit={handleSubmit}>
        <Stack spacing="4" pb={{ base: 8, lg: 0 }}>
          <Text fontSize="sm" fontWeight="semibold" color="fg.subtle">
            Stay up to date
          </Text>
          <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} maxW={{ lg: '360px' }}>
            <Input
              placeholder="Enter your email"
              type="email"
              backgroundColor={'white'}
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              color={'black'}
            />
            <Button type="submit" flexShrink={0}>
              Subscribe
            </Button>
          </Stack>
        </Stack>
      </form>
    </Flex>
    <Divider />
    <Flex
      pb="12"
      pt="5"
      px={4}
      justify="space-between"
      direction={{ base: 'column', md: 'row' }}
      align={{ base: 'start', md: 'center' }}
      maxW={"1280px"} width="100%"
    >
      <Flex
        justifyContent={{ base: 'space-between' }}
        alignItems={'center'}
        width={{ base: 'full', sm: '100%' }} maxW={'100vw'}
        flexDirection={{ base:'column', sm:'row'}}
      >
        <Flex gap={3} alignItems={'center'} direction={{base:'column', md:'row'}}>
          <Img src={logoImage} width={"200px"} maxW={'50vw'} />
          {/*
          <ButtonGroup variant="tertiary" maxW={'50vw'}>
            <IconButton as="a" href="#" aria-label="LinkedIn" icon={<FaLinkedin />} />
            <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub />} />
            <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter />} />
          </ButtonGroup>*/}
          <a href="https://legitscript.com" target="_blank" title="Verify LegitScript Approval">
            <img src="https://static.legitscript.com/seals/21044536.png" alt="LegitScript approved" width="117" height="100" border="0" />
          </a>

          {/*
          <Img src={'legitscript.png'} maxH={'100px'} 
            onClick={() => {window.open('https://www.legitscript.com/websites/?checker_keywords=symliphy.com','_blank')}}
            _hover={{'cursor':'pointer', 'opacity':'0.9'}}
          />*/}
          <Box mt={{base:6,md:3}} ml={{base:0, md:-10}}>
            <TrustBox />
          </Box>
        </Flex>
      </Flex>
      <Text fontSize="sm" color="fg.subtle" pt={4} px={2} textAlign={{base:'center', sm:'start', md:'end'}} w={'100%'}>
        &copy; {new Date().getFullYear()} DigitalCareForMe LLC. All rights reserved.
      </Text>
    </Flex>
    {/* Modal for "Patient Profile" */}
    <Modal isOpen={isOpenPatientProfile} onClose={onClosePatientProfile} size={{base:'full',md:"2xl"}}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>My Patient Portal</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontWeight='bold' fontSize={'1.2em'}>Existing Patients:</Text>
          <Text py={3}>
            To access your patient profile information or to see messages from your provider on an <b>active case</b> (submitted within the last 72 hours), please login using the button below. Once in the portal, please click on the bell icon ( <FiBell style={{ display: 'inline', verticalAlign: 'text-bottom' }} /> ) at the top of the screen to review provider messages on your case.
          </Text>
          <Flex gap={3} flexDirection={{base:'column', md:'row'}}>
            <Button variant="primaryOrangeSolid" mr={3} onClick={handlePatientProfile}>
              Visit Your Patient Portal
            </Button>
          </Flex>
          <Text fontWeight='bold' fontSize={'1.2em'} pt={7}>New Patient?</Text>
          <Text py={3}>Welcome! Please
            pick a treatment to get started, or if
            you're unsure, start a general
            consultation to work with one of our
            healthcare providers.</Text>
          <Flex gap={3} flexDirection={{base:'column', md:'row'}}>
            <Button variant="primaryBlueSolid" mr={3} onClick={handleFindTreatmentClick}>
              Find Treatment
            </Button>
            <Button variant="primaryBlueOutline" mr={3} onClick={handleStartGeneralConsultClick}>
              Start General Consultation
            </Button>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClosePatientProfile}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </Flex>
  )
}
