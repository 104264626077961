import { Navbar } from '../components/Navbar.jsx';
import React from 'react';
import {
  Box, Heading, Text, VStack
} from "@chakra-ui/react";
import { Footer } from '../components/Footer.jsx';

export const OurStory = () => {
  return (
    <>
      <Navbar />
      <Box bg="gray.50" py={12} px={5}>
        <VStack spacing={8} maxW={'1280px'} w={'100%'} margin={'0 auto'} align="stretch">
          <Heading as="h1" size="2xl" textAlign="center">Our Story</Heading>
          <Text fontSize="xl" color={'primaryBlue.600'} fontWeight='600' textAlign="center">
            Healthcare Empowerment — Harnessing Technology to Transform
          </Text>
          
          <Box>
            <Heading as="h2" size="xl" mb={4}>About Us</Heading>
            <Text fontSize="lg" mb={4} fontWeight='400'>
              From a deep understanding of the challenges we all encounter when illness strikes, we set out to revolutionize digital healthcare treatment. Over decades spent in healthcare and pharmacy, we witnessed the challenges in accessing care. We saw their frustrations waiting to see a provider, and navigating busy family and work schedules.
            </Text>
            <Text fontSize="lg" mb={4} fontWeight='400'>
              We envisioned harnessing the power of digital technology to provide on-demand access to care and overcome these obstacles. A bold new path to access healthcare where you're not alone.
            </Text>
            <Text fontSize="lg" mb={4} fontWeight='400'>
              With Symliphy, we deliver healthcare access with speed, simplicity, and privacy. Created to connect you with lightning-fast relief, Symliphy eliminates the typical hassles that delay prompt treatment and empowers you to take control of your health.
            </Text>
            <Text fontSize="lg" mb={4} fontWeight='400'>
              Break free from the confines of traditional healthcare constraints. No more waiting—enjoy seamless, user-friendly access to symptom treatment whenever and wherever you need it. No pre-approvals, appointments, or scheduling headaches.
            </Text>
            <Text fontSize="lg" mb={4} fontWeight='400'>
              Symliphy's mission extends beyond convenience—it empowers you to seize control of your health journey.
            </Text>
            <Text fontSize="lg" mb={4} fontWeight='400'>
              In the face of health challenges, Symliphy is your ally, providing on-demand access to symptom treatment, fast.
            </Text>
            <Text fontSize="2xl" mt={8} fontWeight='600'>
              Symliphy - From Symptom to Care, FASTER.
            </Text>
          </Box>
        </VStack>
      </Box>
      <Footer />
    </>
  );
};
