import {
  Flex
} from '@chakra-ui/react'

export const TreatmentHeader = ({name, category, description, image}) => {

  let backgroundImageUrl = ''

  if (name && name.includes('General Consult')) {
    backgroundImageUrl = 'url(../photos/general2.jpg)'
  } else if (name === 'Medication Refill') {
    backgroundImageUrl = 'url(../photos/refill2.jpg)'
  } else if (image) {
    backgroundImageUrl = `url(../${image})`
  } else if (name && name.includes('Weight')) {
    backgroundImageUrl = 'url(../photos/weightloss.jpg)'
  }
  else {
    backgroundImageUrl = 'none'
  }

  return (
    <>
      <Flex
        as="section"
        background={backgroundImageUrl}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition={{base:"85% 20%",md:"center 20%"}}
        pt={{ base: 12, md: 20 }} 
        w={'100vw'}
        justifyContent={'center'} flexDirection={'column'}
      >
        <Flex
          as="section"
          py={{ base: 3, md: 6 }}
          px={{ base: 4, md: 8 }}
          justifyContent={'center'} flexDirection={'column'}
          w={'100%'} mb={{base:8,md:12}}
          alignItems={'center'} gap={{base:3,md:6}}
        >
          {category &&
            <Flex maxW={'1280px'} w={'100%'} 
              fontSize={{ base: 'lg', md: '2xl' }} 
              fontWeight="600" 
              color={'primaryOrange.default'}
              lineHeight={{base:'24px',md:'32px'}}
            >
              {category}
            </Flex>
          }
          <Flex maxW={'1280px'} w={'100%'}
            fontSize={{ base: '2xl', md: '5xl' }} 
            fontWeight="1000"
            color='primaryBlue.default'
            lineHeight={{base:'24px',md:'32px'}}
          >
            {name}
          </Flex>
        </Flex>

        {description &&
          <Flex
            as="section"
            background={"secondaryBlue.opacity80"}
            py={{ base: 3, md: 6 }}
            px={{ base: 4, md: 8 }}
            justifyContent={'center'}
          >
            <Flex maxW={'1280px'} w={'100%'} 
              fontSize={{ base: 'sm', md: 'lg' }} 
              fontWeight={{ base: '500', md: 'bold' }} 
              color={"white"}
              lineHeight={{base:'24px',md:'32px'}}
            >
              {description}
            </Flex>
          </Flex>
        }
      </Flex>
    </>
  )
}