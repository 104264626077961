import { Navbar } from '../components/Navbar.jsx';
import React from 'react';
import {
  Box, Heading, Text, VStack, OrderedList, ListItem
} from "@chakra-ui/react";
import { Footer } from '../components/Footer.jsx';

export const BrandStory = () => {
  return (
    <>
      <Navbar />
      <Box bg="gray.50" py={12} px={5}>
        <VStack spacing={8} maxW={'1280px'} w={'100%'} margin={'0 auto'} align="stretch">
          <Heading as="h1" size="2xl" textAlign="center">Symliphy Brand Promise</Heading>
          <Text fontSize="xl" color={'primaryBlue.600'} fontWeight='600' textAlign="center">
            Easy, Accessible, On-Demand care to deliver symptom relief so you begin to feel better, faster.
          </Text>
          
          <Box>
            <Heading as="h2" size="xl" mb={4}>About Us</Heading>
            <Text fontSize="lg" mb={4} fontWeight='400'>
              At Symliphy, we believe everyone should have access to high-quality health care when they need it, for chronic or new conditions alike.
            </Text>
            <Text fontSize="lg" mb={4} fontWeight='400'>
              We know life can be hectic, scheduling medical appointments can be daunting, and juggling it all can leave you stressed and delay treatment. We harness digital technology to break through these obstacles and access care. No appointments, no referrals, pre-approvals, or wait required.
            </Text>
            <Text fontSize="lg" mb={4} fontWeight='400'>
              We connect you to treatment lightning-fast, with the power of a team of doctors and healthcare providers. Symliphy propels you through time to each step, fast as a flash, discussing your health concerns and providing guidance through simple checklists and virtual visits.
            </Text>
            <Heading as="h3" size="md" mt={8} mb={2} fontWeight='600'>Three simple steps to get the relief you need:</Heading>
            <OrderedList spacing={2} pl={5} mb={4}>
              <ListItem>Browse Common Symptoms</ListItem>
              <ListItem>Complete Treatment Protocol</ListItem>
              <ListItem>Prescription Sent to Pharmacy</ListItem>
            </OrderedList>
            <Text fontSize="lg" fontWeight='400'>
              As soon as you recognize symptoms, connect with relief fast at Symliphy.
            </Text>
            <Text fontSize="2xl" mt={8} fontWeight='600'>
              Symliphy - From Symptom to Care, FASTER.
            </Text>
          </Box>
        </VStack>
      </Box>
      <Footer />
    </>
  );
};
