import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Check if the browser is Edge and use the workaround
    const isEdge = /Edge\/\d./i.test(navigator.userAgent);
    if (isEdge) {
      document.body.scrollTop = 0; // For Edge
      document.documentElement.scrollTop = 0; // For IE and other browsers
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};
