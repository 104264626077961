import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert, AlertIcon,
  Box, Flex, Heading
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Footer } from '../components/Footer';
import { Navbar } from '../components/Navbar.jsx';
import { Questions } from '../data/_faqs.ts';

export const FAQs = () => {
  const location = useLocation();
  const weightLossRef = useRef(null);
  const [defaultIndex, setDefaultIndex] = useState(-1);

  // Function to group questions by category
  const groupQuestionsByCategory = (questions) => {
    return questions.reduce((acc, question) => {
      acc[question.category] = acc[question.category] || [];
      acc[question.category].push(question);
      return acc;
    }, {});
  };

  const questionsByCategory = groupQuestionsByCategory(Questions);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get('section');

    if (section === 'weight-loss' || section === 'weightloss') {
      if (weightLossRef.current) {
        weightLossRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      // Find the index of the "Weight Loss Questions" category
      const weightLossIndex = Object.keys(questionsByCategory).indexOf('Weight Loss Questions');
      setDefaultIndex(weightLossIndex);
    }
  }, [location, questionsByCategory]);

  return (
    <>
      <Navbar />
      <Flex justifyContent={'center'} py={12} px={5} maxW={'1280px'} w={'100%'} margin={'0 auto'} flexDirection={'column'} alignItems={'center'}>
        <Heading as="h1" size="xl" mb={6}>Frequently Asked Questions</Heading>
        <Alert status='info' size="md" mb={6}><AlertIcon />Symliphy is not appropriate for serious conditions that require immediate attention or emergency care. If you experience any life-threatening issues, call 911 or go to an emergency room immediately.</Alert>
        {Object.entries(questionsByCategory).map(([category, questions], index) => (
          <Box key={category} mb={10} w={'100%'} ref={category === 'Weight Loss Questions' ? weightLossRef : null}>
            <Heading as="h2" size="lg" mb={4}>{category}</Heading>
            <Accordion allowMultiple w={'100%'} defaultIndex={defaultIndex === index ? [0] : []}>
              {questions
                .filter(question => question.description !== '')
                .map(question => (
                <AccordionItem key={question.title}>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight={'700'}>
                      {question.title}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    {question.description}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        ))}
      </Flex>
      <Footer />
    </>
  )
}
