// ChatbotContext.jsx
import React, { createContext, useContext, useState } from 'react';

const ChatbotContext = createContext();

export const ChatbotProvider = ({ children }) => {
  const [showBot, setShowBot] = useState(false);

  const toggleBot = () => {
    console.log("toggling bot")
    setShowBot(prevState => !prevState);
  };

  return (
    <ChatbotContext.Provider value={{ showBot, toggleBot }}>
      {children}
    </ChatbotContext.Provider>
  );
};

export const useChatbot = () => {
  const context = useContext(ChatbotContext);
  if (context === undefined) {
    throw new Error('useChatbot must be used within a ChatbotProvider');
  }
  return context;
};

export default ChatbotProvider;
