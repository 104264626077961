import { Navbar } from '../components/Navbar.jsx'
import React from 'react';
import {
  Flex, Heading, Text, OrderedList, ListItem, Link
} from "@chakra-ui/react";
import { Footer } from '../components/Footer.jsx'

export const HIPAAAuthorization = () => {

  return (
    <>
      <Navbar />
      <Flex justifyContent={'center'} py={12} px={5} 
        maxW={'1280px'} w={'100%'} margin={'0 auto'} 
        flexDirection={'column'} alignItems={'start'}
        gap={3} 
      >
        <Heading as="h1" size="xl" mb={6} textAlign={'center'} w={'100%'}>HIPAA Acknowledgement and Authorization</Heading>

        <Text fontWeight={'bold'} mb={6}>Acknowledgement and Authorization for Use and Disclosure of Protected Health Information to GenieMD Medical Group, MO, P.C. and Affiliates</Text>     
        <Text>This is an Authorization for Use and Disclosure of Protected Health Information (the “Authorization”) in compliance with federal privacy laws, including the Health Insurance Portability and Accountability Act of 1996 and the Health Information Technology for Economic and Clinical Health Act (collectively, “HIPAA”).</Text>
        <Text fontWeight={'bold'}>Please read this entire form before signing.  Electronic signatures, including clicking “I agree” as part of using DC4ME’s telehealth service platform, suffice.  Entities that arrange the provision of health care services for people, such as DigitalCareForMe, LLC d/b/a Symliphy, an Ohio limited liability company (“DC4ME”), must obtain a signed authorization from the individual or the individual’s legally-authorized representative to electronically disclose or otherwise transmit that individual’s protected health information.  Authorization is not required for disclosures related to treatment, payment, health care operations, performing certain insurance functions, or as may be otherwise authorized by law.  Individuals cannot be denied treatment based on a failure to sign this authorization form, and a refusal to sign this authorization form will not affect payment, enrollment, or eligibility for benefits.</Text>

        <Heading as="h2" size="md" mt={6} textAlign={'start'} w={'100%'}>I.	Acknowledgement and Authorization for the Use or Disclosure of PHI</Heading>
        <Text>I authorize DC4ME and its affiliates, contractors, and employees to disclose certain Protected Health Information that I or my dependent provide through DC4ME’s mobile app and/or website that pertains to me or my dependent (“PHI”) to GenieMD Medical Group, MO, P.C., a Missouri professional corporation, and its affiliates (collectively, “GenieMD”) for the purposes of providing health care and/or prescription services as indicated in or necessitated by any health symptom questionnaire(s) I complete on behalf of myself, or based on any information I provide to DC4ME.</Text>
        <Text>A non-exclusive list of PHI that may be disclosed by DC4ME to GenieMD includes, but is not limited to:</Text>

        <OrderedList pl={3}>
          <ListItem>Contact Information (for example, email address);</ListItem>
          <ListItem>Demographic Information (for example, date of birth);</ListItem>
          <ListItem>Symptom information, including questionnaire answers and any health-related information provided independently or in conjunction with any questionnaire;</ListItem>
          <ListItem>Account Information (for example, DC4ME account profile); and</ListItem>
          <ListItem>My complete patient file, including medical history and all information related thereto.</ListItem>
        </OrderedList>

        <Heading as="h2" size="md" mt={6} textAlign={'start'} w={'100%'}>II.	Important Information About Your Rights</Heading>

        <OrderedList pl={3}>
        <ListItem>This Authorization is voluntary.  Refusing to sign this Authorization will not prevent me from obtaining health care services from any healthcare provider that provides asynchronous and/or live telehealth services through DC4ME’s health services vehicles by reaching out to the healthcare provider directly.</ListItem>
        <ListItem>I may revoke this Authorization at any time by sending a written revocation notice to DC4ME and may use DC4ME’s form to do so.  The revocation will not have any effect on any disclosure that any healthcare provider took in reliance on this Authorization before receiving my revocation notice.</ListItem>
        <ListItem>Information disclosed pursuant to this Authorization may be re-disclosed by the recipient, and this redisclosure will no longer be protected by HIPAA.<br/><Link href="files/HIPAA Revocation FINAL.030124.docx">Click here to Download HIPAA Revocation Form</Link></ListItem>
        <ListItem>I have a right to a copy of this authorization.</ListItem>
        <ListItem>This Authorization expires five years after I cease to receive any services from any healthcare provider provided by DC4ME or any entity with which it contracts.</ListItem>
        </OrderedList>
      </Flex>
      <Footer />
    </>
  )
}