export const treatmentData = [
  {
    id: "1",
    name: "Headache",
    path: "headache",
    genieMdName: "Headache",
    primaryCategory: "Urgent Care",
    priceRange: "$8 to $14",
    description:
      "A headache is a common symptom characterized by pain or discomfort in the head or neck region. Headaches can vary in intensity, duration, and underlying causes. They may be primary headaches (not associated with any underlying medical condition) or secondary headaches (resulting from another health issue). Common primary headache types include tension-type headaches, migraines, and cluster headaches.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Thermometer"],
    commonMedications: ["Sumatriptan", "Naproxen", "Ibuprofen"],
    symptoms: [
      {
        sectionName: "Pain Location",
        sectionDetails: ["Pain or discomfort in the head, scalp, or neck."],
        sectionOrder: 1,
      },
      {
        sectionName: "Intensity",
        sectionDetails: ["Headaches can range from mild to severe."],
        sectionOrder: 2,
      },
      {
        sectionName: "Duration",
        sectionDetails: [
          "Headaches may be episodic (occurring occasionally) or chronic (frequent and persistent).",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Type of Pain",
        sectionDetails: ["Dull, aching, throbbing, or sharp pain sensations."],
        sectionOrder: 4,
      },
      {
        sectionName: "Associated Symptoms",
        sectionDetails: [
          "Headaches may be accompanied by symptoms such as nausea, vomiting, sensitivity to light (photophobia), or sensitivity to sound (phonophobia).",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Location-Specific Symptoms",
        sectionDetails: [
          "Different headache types may have specific characteristics. For example, migraines often involve one side of the head and are associated with aura (visual disturbances) in some cases.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Triggers",
        sectionDetails: [
          "Headaches may be triggered by various factors, including stress, lack of sleep, dehydration, certain foods, or environmental factors.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Aura (Migraines)",
        sectionDetails: [
          "Some individuals with migraines may experience auras, which are visual or sensory disturbances that precede the headache.",
        ],
        sectionOrder: 8,
      },
      {
        sectionName: "Neck Pain and Tension",
        sectionDetails: [
          "Tension-type headaches may be associated with muscle tension and discomfort in the neck and shoulders.",
        ],
        sectionOrder: 9,
      },
    ],
  },
  {
    id: "2",
    name: "Medication Refill",
    path: "medication-refill",
    genieMdName: "Medication Refill",
    primaryCategory: "",
    priceRange: "Varies",
    description:
      "Medication refill refers to the process of obtaining a renewed supply of prescription medication to continue an ongoing treatment plan. From Inhalers to Diabetes medication.",
    whatYouNeed: [
      "Meds",
      "Allergies",
      "Conditions",
      "Picture Condition",
      "Lab Result",
      "Rx Picture",
    ],
    symptoms: [
      {
        sectionName: "List of commonly refilled medications",
        sectionDetails: [
          "Erectile Dysfunction Medicine",
          "Thyroid Medicine",
          "Blood Pressure Medicine",
          "Cholesterol Medicine",
          "Vitamin D Supplement",
          "Steroids (Prednisone, Etc)",
          "Heart Burn Medicine",
          "Antibiotics",
          "Depression/Anxiety Medicine",
          "Diabetes Medicine",
          "Iron Deficiency Anemia",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Restrictions",
        sectionDetails: [
          "We are unable to refill scheduled medications. (Examples - Opioids, Adderall)",
          "The refill length is determined by the prescribing doctor",
        ],
        sectionOrder: 2,
      },
    ],
  },
  {
    id: "3",
    name: "Migraine - Headache",
    path: "migraine-headache",
    genieMdName: "Migraine - Headache",
    primaryCategory: "Urgent Care",
    priceRange: "$8 to $14",
    description:
      "Migraine is a type of primary headache characterized by recurrent, throbbing head pain often accompanied by other symptoms. Migraines can significantly impact daily life and may be preceded or accompanied by sensory disturbances known as aura. They are a neurological condition and can be triggered by various factors. Migraines are typically more severe than tension-type headaches and can last for hours to days.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Amitriptyline HCl", "Divalproex Sodium", "Naproxen Sodium", "Propranolol", "Sumatriptan Succinate", "Topiramate"],
    symptoms: [
      {
        sectionName: "Throbbing Pain",
        sectionDetails: [
          "Intense, pulsating or throbbing pain, often on one side of the head.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Duration",
        sectionDetails: [
          "Migraines typically last for a few hours to a few days.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Sensitivity to Light (Photophobia)",
        sectionDetails: ["Increased sensitivity to light."],
        sectionOrder: 3,
      },
      {
        sectionName: "Sensitivity to Sound (Phonophobia)",
        sectionDetails: ["Increased sensitivity to sound."],
        sectionOrder: 4,
      },
      {
        sectionName: "Nausea",
        sectionDetails: ["Nausea or vomiting."],
        sectionOrder: 5,
      },
      {
        sectionName: "Aura",
        sectionDetails: [
          "Some individuals may experience visual or sensory disturbances known as aura before or during the headache. Auras may include flashes of light, blind spots, or tingling sensations.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Visual Disturbances",
        sectionDetails: [
          "Visual symptoms such as seeing zigzag lines, shimmering lights, or temporary vision loss may occur.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Pain Aggravated by Physical Activity",
        sectionDetails: ["Routine physical activities may worsen the pain."],
        sectionOrder: 8,
      },
      {
        sectionName: "Aura Variability",
        sectionDetails: [
          "Auras can vary from person to person and from one migraine episode to another.",
        ],
        sectionOrder: 9,
      },
      {
        sectionName: "Prodrome Phase",
        sectionDetails: [
          "Some individuals experience symptoms such as mood changes, food cravings, or increased yawning in the hours or days leading up to a migraine (prodrome phase).",
        ],
        sectionOrder: 10,
      },
    ],
  },
  {
    id: "4",
    name: "Rash",
    path: "rash",
    genieMdName: "Rash",
    primaryCategory: "Urgent Care",
    priceRange: "$6 to $10",
    description:
      "A rash is a change in the skin's appearance, color, or texture that may involve redness, itching, and discomfort. Rashes can have various causes, including infections, allergic reactions, autoimmune disorders, or exposure to irritants. They can appear as raised or flat, and their characteristics may vary depending on the underlying cause.",
    whatYouNeed: [
      "Meds",
      "Allergies",
      "Conditions",
      "Picture Condition",
      "Thermometer",
    ],
    commonMedications: ["Prednisone", "Mupirocin Topical Ointment", "Triamcinolone Topical Cream"],
    symptoms: [
      {
        sectionName: "Redness",
        sectionDetails: ["Skin appears reddened or flushed."],
        sectionOrder: 1,
      },
      {
        sectionName: "Itching",
        sectionDetails: ["Itching or discomfort in the affected area."],
        sectionOrder: 2,
      },
      {
        sectionName: "Bumps or Lesions",
        sectionDetails: [
          "Presence of raised bumps, blisters, or flat lesions on the skin.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Texture Changes",
        sectionDetails: [
          "Changes in skin texture, such as scaling, peeling, or blistering.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Warmth or Swelling",
        sectionDetails: ["The affected area may feel warm or swollen."],
        sectionOrder: 5,
      },
      {
        sectionName: "Pain",
        sectionDetails: [
          "Some rashes may be associated with pain or tenderness.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Distribution",
        sectionDetails: [
          "Rashes can be localized to a specific area or spread across a larger area of the body.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Fever",
        sectionDetails: [
          "In some cases, a rash may be accompanied by fever, especially in infectious causes.",
        ],
        sectionOrder: 8,
      },
      {
        sectionName: "Fluid-filled Blisters",
        sectionDetails: [
          "Fluid-filled blisters may be present, particularly in conditions like herpes or contact dermatitis.",
        ],
        sectionOrder: 9,
      },
      {
        sectionName: "Scaling or Crusting",
        sectionDetails: [
          "Skin may appear scaly or develop crusts in certain types of rashes.",
        ],
        sectionOrder: 10,
      },
    ],
  },
  {
    id: "5",
    name: "Rash - Pediatric",
    path: "rash-pediatric",
    genieMdName: "Rash - Pediatric",
    primaryCategory: "Pediatric Health",
    priceRange: "$6 to $10",
    description:
      "A rash in pediatric patients refers to a change in the appearance, color, or texture of the skin that may involve redness, itching, or discomfort. Rashes in children can have various causes, including infections, allergic reactions, environmental factors, or underlying medical conditions. They can appear on different parts of the body and vary in characteristics based on the specific cause.",
    whatYouNeed: [
      "Meds",
      "Allergies",
      "Conditions",
      "Picture Condition",
      "Thermometer",
    ],
    commonMedications: ["Corticosteroids", "Antihistamines", "Mupirocin Topical ointment"],
    symptoms: [
      {
        sectionName: "Redness",
        sectionDetails: ["The affected skin may appear reddened or flushed."],
        sectionOrder: 1,
      },
      {
        sectionName: "Itching",
        sectionDetails: [
          "Children with rashes may exhibit signs of itching or discomfort.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Bumps or Lesions",
        sectionDetails: [
          "Presence of raised bumps, blisters, or flat lesions on the skin.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Distribution",
        sectionDetails: [
          "Rashes can be localized to a specific area or spread across larger areas of the body.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Texture Changes",
        sectionDetails: [
          "Changes in skin texture, such as scaling, peeling, or blistering.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Warmth or Swelling",
        sectionDetails: ["The affected area may feel warm or swollen."],
        sectionOrder: 6,
      },
      {
        sectionName: "Fever",
        sectionDetails: [
          "In some cases, a rash in children may be accompanied by fever, particularly in infectious causes.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Pain or Discomfort",
        sectionDetails: [
          "Some rashes may be associated with pain or tenderness.",
        ],
        sectionOrder: 8,
      },
      {
        sectionName: "Fluid-filled Blisters",
        sectionDetails: [
          "Fluid-filled blisters may be present, depending on the underlying cause.",
        ],
        sectionOrder: 9,
      },
      {
        sectionName: "Crusting or Scaling",
        sectionDetails: [
          "Skin may develop crusts or scaling in certain types of rashes.",
        ],
        sectionOrder: 10,
      },
    ],
  },
  {
    id: "6",
    name: "Shortness of Breath",
    path: "shortness-of-breath",
    genieMdName: "Shortness of Breath",
    primaryCategory: "Urgent Care",
    priceRange: "$10 to $25",
    description:
      "Shortness of breath, also known as dyspnea, is a sensation of breathing discomfort or difficulty that can range from mild to severe. It is a common symptom and can be caused by various underlying conditions affecting the respiratory or cardiovascular systems. Shortness of breath may occur suddenly or develop gradually and may be associated with other symptoms, such as chest pain, cough, or wheezing.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Prednisone"],
    symptoms: [
      {
        sectionName: "Difficulty Breathing",
        sectionDetails: [
          "A sensation of not getting enough air or feeling breathless.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Rapid Breathing",
        sectionDetails: ["Breathing at a faster rate than normal."],
        sectionOrder: 2,
      },
      {
        sectionName: "Chest Tightness",
        sectionDetails: ["A feeling of tightness or pressure in the chest."],
        sectionOrder: 3,
      },
      {
        sectionName: "Wheezing",
        sectionDetails: [
          "High-pitched whistling sounds during breathing, often associated with airway constriction.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Cough",
        sectionDetails: [
          "Coughing may be present, especially if related to respiratory conditions.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Fatigue",
        sectionDetails: [
          "Feeling tired or exhausted, which can be exacerbated by difficulty breathing.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Lightheadedness or Dizziness",
        sectionDetails: [
          "Feeling lightheaded or dizzy, which can be a result of inadequate oxygen intake.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Bluish Tint (Cyanosis)",
        sectionDetails: [
          "Lips or skin may take on a bluish tint in severe cases, indicating a lack of oxygen.",
        ],
        sectionOrder: 8,
      },
      {
        sectionName: "Orthopnea",
        sectionDetails: [
          "Difficulty breathing when lying down, often relieved by sitting up or standing.",
        ],
        sectionOrder: 9,
      },
    ],
  },
  {
    id: "7",
    name: "Sinus Infection",
    path: "sinus-infection",
    genieMdName: "Sinus Infection",
    primaryCategory: "Urgent Care",
    priceRange: "$6 to $14",
    description:
      "A sinus infection, also known as sinusitis, is the inflammation or swelling of the tissue lining the sinuses. Sinuses are air-filled cavities located in the skull around the nose and eyes. Sinus infections can be acute (short-term) or chronic (lasting longer than 12 weeks). They are often caused by viral, bacterial, or fungal infections, or by conditions that block the sinus passages, preventing proper drainage.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Thermometer"],
    commonMedications: ["Ondansetron", "Amoxicillin", "Amoxicillin-Clavulanate", "Fluconazole", "Azithromycin", "Ipratropium Nasal spray", "Azithromycin 5 Day Dose Pack", "Ofloxacin Ophthalmic solution", "Doxycycline Monohydrate", "MethylPREDNISolone", "Promethazine-Dextromethorphan", "Medrol Dosepak", "Amoxicillin-Clavulanate"],
    symptoms: [
      {
        sectionName: "Nasal Congestion",
        sectionDetails: ["Blockage or stuffiness in the nasal passages."],
        sectionOrder: 1,
      },
      {
        sectionName: "Facial Pain or Pressure",
        sectionDetails: [
          "Pain or pressure around the eyes, cheeks, and forehead.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Headache",
        sectionDetails: [
          "A dull or throbbing pain in the head, often worsened by bending forward.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Postnasal Drip",
        sectionDetails: [
          "Mucus draining down the back of the throat, leading to a cough or throat irritation.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Fatigue",
        sectionDetails: ["Feeling tired or lethargic."],
        sectionOrder: 5,
      },
      {
        sectionName: "Loss of Smell",
        sectionDetails: ["Reduced or loss of sense of smell."],
        sectionOrder: 6,
      },
      {
        sectionName: "Bad Breath",
        sectionDetails: ["Foul-smelling breath due to sinus drainage."],
        sectionOrder: 7,
      },
      {
        sectionName: "Fever",
        sectionDetails: [
          "In some cases, sinus infections may be accompanied by a low-grade fever.",
        ],
        sectionOrder: 8,
      },
      {
        sectionName: "Cough",
        sectionDetails: ["A cough that may be worse at night."],
        sectionOrder: 9,
      },
      {
        sectionName: "Dental Pain",
        sectionDetails: ["Pain in the upper jaw and teeth."],
        sectionOrder: 10,
      },
      {
        sectionName: "Ear Pressure",
        sectionDetails: ["Feeling of pressure or fullness in the ears."],
        sectionOrder: 11,
      },
    ],
  },
  {
    id: "8",
    name: "Sore Throat",
    path: "sore-throat",
    genieMdName: "Sore Throat",
    primaryCategory: "Urgent Care",
    priceRange: "$9 to $15",
    description:
      "A sore throat is a common symptom characterized by pain, irritation, or scratchiness in the throat. It may be caused by viral or bacterial infections, environmental factors, or other conditions affecting the throat. Sore throats can vary in severity, and associated symptoms may include difficulty swallowing, hoarseness, and swollen glands.",
    whatYouNeed: [
      "Meds",
      "Allergies",
      "Conditions",
      "Picture Condition",
      "Thermometer",
    ],
    commonMedications: [" Amoxicillin", "Albuterol", "Amoxicillin", "Azithromycin 5 Day Dose Pack", "Nystatin", "Ipratropium Nasal Spray", "Fexofenadine"],
    symptoms: [
      {
        sectionName: "Throat Pain",
        sectionDetails: [
          "Pain or discomfort in the throat, which may be worsened by swallowing or talking.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Difficulty Swallowing",
        sectionDetails: [
          "Pain or discomfort while swallowing, often accompanied by a sensation of something stuck in the throat.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Hoarseness",
        sectionDetails: [
          "Changes in voice pitch or quality, making the voice sound rough or strained.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Swollen Glands",
        sectionDetails: [
          "Enlarged and tender lymph nodes (glands) in the neck.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Redness",
        sectionDetails: ["Redness or inflammation of the throat tissues."],
        sectionOrder: 5,
      },
      {
        sectionName: "Cough",
        sectionDetails: [
          "A dry or productive cough may be present, especially if the sore throat is caused by a respiratory infection.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Runny Nose",
        sectionDetails: [
          "Congestion or a runny nose may accompany a sore throat, particularly in viral infections.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Sneezing",
        sectionDetails: [
          "Sneezing may occur in cases of viral infections like the common cold.",
        ],
        sectionOrder: 8,
      },
      {
        sectionName: "Fever",
        sectionDetails: [
          "In some cases, a sore throat may be accompanied by fever.",
        ],
        sectionOrder: 9,
      },
    ],
  },
  {
    id: "9",
    name: "Sore Throat - Pediatric",
    path: "sore-throat-pediatric",
    genieMdName: "Sore Throat-Pediatric",
    primaryCategory: "Pediatric Health",
    priceRange: "$9 to $15",
    description:
      "Common childhood condition involving irritation, pain, or discomfort in the throat. Often triggered by viral infections like the common cold or flu, and occasionally by bacterial infections such as strep throat.",
    whatYouNeed: [
      "Meds",
      "Allergies",
      "Conditions",
      "Picture Condition",
      "Thermometer",
    ],
    commonMedications: ["Amoxicillin"],
    symptoms: [
      {
        sectionName: "Throat Pain",
        sectionDetails: [
          "Pain or discomfort in the throat, which may be worsened by swallowing or talking.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Difficulty Swallowing",
        sectionDetails: [
          "Pain or discomfort while swallowing, often accompanied by a sensation of something stuck in the throat.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Hoarseness",
        sectionDetails: [
          "Changes in voice pitch or quality, making the voice sound rough or strained.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Swollen Glands",
        sectionDetails: [
          "Enlarged and tender lymph nodes (glands) in the neck.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Redness",
        sectionDetails: ["Redness or inflammation of the throat tissues."],
        sectionOrder: 5,
      },
      {
        sectionName: "Cough",
        sectionDetails: [
          "A dry or productive cough may be present, especially if the sore throat is caused by a respiratory infection.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Runny Nose",
        sectionDetails: [
          "Congestion or a runny nose may accompany a sore throat, particularly in viral infections.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Sneezing",
        sectionDetails: [
          "Sneezing may occur in cases of viral infections like the common cold.",
        ],
        sectionOrder: 8,
      },
      {
        sectionName: "Fever",
        sectionDetails: [
          "In some cases, a sore throat may be accompanied by fever.",
        ],
        sectionOrder: 9,
      },
    ],
  },
  {
    id: "10",
    name: "Urinary Tract Infection",
    path: "urinary-tract-infection",
    genieMdName: "Urinary Tract Infection",
    primaryCategory: "Urgent Care",
    priceRange: "$8 to $12",
    description:
      "Common bacterial infection affecting any part of the urinary system, including the bladder, kidneys, ureters, and urethra. More prevalent in females but can occur in individuals of any age and gender.",
    whatYouNeed: [
      "Meds",
      "Allergies",
      "Conditions",
      "Photo of Test",
      "Thermometer",
    ],
    commonMedications: ["Amoxicillin", "Ciprofloxacin", "Macrobid", "Nitrofurantoin Monohydrate Macrocrystalline", "Cephalexin", "Phenazopyridine HCl", "Sulfamethoxazole-Trimethoprim", "Amoxicillin", "Ciprofloxacin", "Macrobid"],
    symptoms: [
      {
        sectionName: "Urination",
        sectionDetails: [
          "Frequent, urgent need to urinate.",
          "Burning sensation during urination.",
          "Cloudy or strong-smelling urine.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Pain",
        sectionDetails: [
          "Pelvic pain or discomfort.",
          "Lower abdominal pain.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Fever",
        sectionDetails: [
          "Fever and fatigue in severe cases.",
        ],
        sectionOrder: 3,
      },
    ],
  },
  {
    id: "11",
    name: "Abdominal Pain",
    path: "abdominal-pain",
    genieMdName: "Abdominal pain",
    primaryCategory: "Urgent Care",
    priceRange: "$6 to $9",
    description:
      "Abdominal pain refers to discomfort or pain felt in the area between the chest and the pelvis, commonly known as the abdomen. The pain can range from mild to severe and may be acute or chronic. The abdomen contains various organs, including the stomach, liver, gallbladder, intestines, and appendix, among others. Abdominal pain can arise from issues with these organs or other structures in the abdomen.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Sucralfate", "Omeprazol", "Ondansetron"],
    symptoms: [
      {
        sectionName: "Location and Character of Pain",
        sectionDetails: [
          "The location of the pain may provide clues to its origin (e.g., upper or lower abdomen).",
          "The character of pain can vary, such as sharp, crampy, dull, or stabbing.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Associated Symptoms",
        sectionDetails: [
          "Digestive Symptoms: Nausea, vomiting, diarrhea, constipation, bloating.",
          "Systemic Symptoms: Fever, fatigue, weight loss.",
          "Urinary Symptoms: Painful urination, changes in frequency or color.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Emergency Signs",
        sectionDetails: [
          "Seek immediate medical attention for severe pain, especially if accompanied by high fever, persistent vomiting, or signs of shock (pale skin, rapid breathing).",
        ],
        sectionOrder: 3,
      },
    ],
  },
  {
    id: "12",
    name: "Acute Cough",
    path: "acute-cough",
    genieMdName: "Acute cough",
    primaryCategory: "Urgent Care",
    priceRange: "$6 to $15",
    description:
      "Acute cough refers to the sudden onset of coughing that typically lasts for a short duration, often less than three weeks. It is a common symptom and can be caused by various factors, including viral respiratory infections (such as the common cold or flu), bacterial infections, irritants, or exposure to environmental pollutants. Acute cough is a natural reflex that helps clear the airways of irritants, mucus, or foreign particles.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Thermometer"],
    commonMedications: ["Benzonatate", "Amoxicillin-Clavulanate", "Prednisone", "Albuterol"],
    symptoms: [
      {
        sectionName: "Cough Characteristics",
        sectionDetails: [
          "Dry Cough: A cough without the production of mucus or phlegm.",
          "Productive Cough: Involves the expulsion of mucus or phlegm.",
          "Hacking Cough: A repetitive, forceful cough.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Associated Symptoms",
        sectionDetails: [
          "Sore Throat: Common, especially with viral infections.",
          "Runny or Stuffy Nose: Often seen in conjunction with respiratory infections.",
          "Fever: May be present, particularly in infections.",
          "Fatigue: Feeling tired or rundown.",
          "Muscle Aches: Common with viral infections like influenza.",
        ],
        sectionOrder: 2,
      },
    ],
  },
  {
    id: "13",
    name: "Ankle Injury",
    path: "ankle-injury",
    genieMdName: "Ankle Injury",
    primaryCategory: "Urgent Care",
    priceRange: "$9 to $14",
    description:
      "An ankle injury involves damage to the structures surrounding the ankle joint, which includes the bones, ligaments, tendons, and muscles. The injury can range from mild to severe and may occur due to various reasons, such as sprains, strains, fractures, or dislocations. Ankle injuries often result from sudden twists, falls, or direct trauma to the ankle, leading to pain, swelling, and decreased mobility.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Picture Condition"],
    commonMedications: ["Acetaminophen", "Ibuprophen", "Naproxen Sodium", "Diclofenac Sodium", "Misoprostol"],
    symptoms: [
      {
        sectionName: "Pain",
        sectionDetails: [
          "Sharp or dull pain around the ankle joint.",
          "Pain may increase with movement or weight-bearing.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Swelling",
        sectionDetails: [
          "Accumulation of fluid around the injured area.",
          "Swelling may be immediate or develop gradually.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Bruising",
        sectionDetails: [
          "Discoloration or bruising around the ankle due to bleeding under the skin.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Limited Range of Motion",
        sectionDetails: [
          "Difficulty moving the ankle joint fully.",
          "Stiffness or tightness may be present.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Instability",
        sectionDetails: [
          'Feeling of the ankle "giving way" or being unstable.',
          "Difficulty maintaining balance.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Tenderness",
        sectionDetails: [
          "Increased sensitivity to touch around the injured area.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Difficulty Bearing Weight",
        sectionDetails: [
          "Reluctance or inability to put weight on the affected ankle.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Deformity",
        sectionDetails: [
          "In severe cases, there may be visible deformity if a fracture or dislocation has occurred.",
          "Follow the healthcare provider's instructions for rehabilitation and return to activity.",
        ],
        sectionOrder: 8,
      },
    ],
  },
  {
    id: "14",
    name: "Ankle Pain",
    path: "ankle-pain",
    genieMdName: "Ankle Pain",
    primaryCategory: "Urgent Care",
    priceRange: "$9 to $14",
    description:
      "Ankle pain is a common symptom that can result from various causes, including injuries, inflammation, overuse, or underlying medical conditions. The pain may be localized to the ankle joint or radiate from surrounding structures. It can range from mild discomfort to severe, sharp pain and may be associated with other symptoms such as swelling, stiffness, or difficulty walking.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Picture Condition"],
    commonMedications: ["Acetaminophen", "Ibuprophen", "Naproxen Sodium", "Diclofenac Sodium", "Misoprostol"],
    symptoms: [
      {
        sectionName: "Pain Characteristics",
        sectionDetails: [
          "Sharp or Stabbing Pain: May indicate a sudden injury or acute condition.",
          "Dull Ache or Throbbing Pain: Often associated with chronic conditions or overuse.",
          "Intermittent or Constant Pain: Can vary based on the underlying cause.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Swelling",
        sectionDetails: [
          "Swelling around the ankle joint, which may contribute to the pain.",
          "The affected area may feel warm or appear red.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Stiffness",
        sectionDetails: [
          "Difficulty moving the ankle joint, especially after periods of inactivity.",
          "Stiffness may improve with movement but can worsen with prolonged rest.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Instability",
        sectionDetails: [
          "Feeling of instability or weakness in the ankle, making it challenging to maintain balance.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Limited Range of Motion",
        sectionDetails: [
          "Difficulty flexing, pointing, or rotating the foot and ankle.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Tenderness",
        sectionDetails: [
          "Increased sensitivity to touch or pressure on specific areas of the ankle.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Difficulty Bearing Weight",
        sectionDetails: [
          "Pain or discomfort when putting weight on the affected ankle.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Clicking or Popping Sensation",
        sectionDetails: [
          "Some individuals may experience audible clicks or pops with movement, indicating joint or ligament involvement.",
        ],
        sectionOrder: 8,
      },
    ],
  },
  {
    id: "15",
    name: "Back Pain",
    path: "back-pain",
    genieMdName: "Back Pain",
    primaryCategory: "Urgent Care",
    priceRange: "$7 to $10",
    description:
      "Back pain is a common condition that can range from mild discomfort to severe, debilitating pain. It can affect any part of the back, including the lower back (lumbar region), upper back (thoracic region), or neck (cervical region). Back pain can result from various causes, including muscle strain, ligament sprains, herniated discs, spinal abnormalities, or underlying medical conditions. It may be acute (lasting a few days to a few weeks) or chronic (lasting for months or even years).",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Cyclobenzaprine", "Duloxetine", "Ibuprofen"],
    symptoms: [
      {
        sectionName: "Dull or Sharp Pain",
        sectionDetails: [
          "Pain can be dull, aching, sharp, or stabbing, depending on the underlying cause.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Muscle Stiffness",
        sectionDetails: [
          "Difficulty in moving the back due to muscle tightness or stiffness.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Limited Range of Motion",
        sectionDetails: [
          "Reduced flexibility and difficulty bending or twisting.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Radiating Pain",
        sectionDetails: [
          "Pain that radiates down the leg (sciatica) in cases of nerve compression or irritation.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Local Tenderness",
        sectionDetails: [
          "Tenderness or soreness when touching or pressing on specific areas of the back.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Muscle Spasms",
        sectionDetails: [
          "Involuntary contractions of muscles that can contribute to pain.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Pain with Movement",
        sectionDetails: [
          "Increased pain during certain activities or movements, such as lifting or bending.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Numbness or Tingling",
        sectionDetails: [
          "Sensations of numbness or tingling, particularly if nerves are involved.",
        ],
        sectionOrder: 8,
      },
      {
        sectionName: "Weakness",
        sectionDetails: [
          "Weakness in the legs or difficulty with coordination, especially if nerves are affected.",
        ],
        sectionOrder: 9,
      },
      {
        sectionName: "Pain Aggravated by Certain Positions",
        sectionDetails: [
          "Discomfort that worsens with sitting, standing, or specific postures.",
        ],
        sectionOrder: 10,
      },
      {
        sectionName: "Pain Duration",
        sectionDetails: [
          "Acute back pain may come on suddenly and last for a short duration, while chronic back pain persists for an extended period.",
        ],
        sectionOrder: 11,
      },
    ],
  },
  {
    id: "16",
    name: "COVID-19",
    path: "covid-19",
    genieMdName: "COVID-19",
    primaryCategory: "Urgent Care",
    priceRange: "$7 to $12",
    description:
      "COVID-19 is a highly contagious respiratory illness caused by the novel coronavirus SARS-CoV-2. COVID-19 primarily spreads through respiratory droplets when an infected person talks, coughs, or sneezes. The severity of symptoms can range from mild to severe, and some individuals may remain asymptomatic. Common symptoms include fever, cough, and shortness of breath.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Paxlovid", "Doxycycline Hyclate", "Benzonatate", "Azithromycin"],
    symptoms: [
      {
        sectionName: "Respiratory Symptoms",
        sectionDetails: [
          "Cough: Persistent cough, often dry.",
          "Shortness of Breath: Difficulty breathing or breathlessness.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Systemic Symptoms",
        sectionDetails: [
          "Fever: Elevated body temperature, often one of the first signs.",
          "Fatigue: Feeling unusually tired or weak.",
          "Body Aches: Muscular pain and discomfort.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Respiratory Distress",
        sectionDetails: [
          "Severe cases may progress to acute respiratory distress syndrome (ARDS), leading to significant breathing difficulties.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Loss of Taste or Smell",
        sectionDetails: [
          "Anosmia (loss of smell) and ageusia (loss of taste) have been reported.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Gastrointestinal Symptoms",
        sectionDetails: [
          "Some individuals may experience nausea, vomiting, or diarrhea.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Headache",
        sectionDetails: ["Persistent or severe headaches may occur."],
        sectionOrder: 6,
      },
      {
        sectionName: "Sore Throat",
        sectionDetails: ["Irritation or pain in the throat."],
        sectionOrder: 7,
      },
      {
        sectionName: "Chills and Rigors",
        sectionDetails: ["Shivering or experiencing uncontrollable chills."],
        sectionOrder: 8,
      },
      {
        sectionName: "Congestion or Runny Nose",
        sectionDetails: [
          "Some people may have symptoms resembling the common cold.",
        ],
        sectionOrder: 9,
      },
      {
        sectionName: "Skin Changes",
        sectionDetails: ["Skin rashes or discoloration may be observed."],
        sectionOrder: 10,
      },
      {
        sectionName: "Neurological Symptoms",
        sectionDetails: [
          "Reports of neurological symptoms, such as confusion or difficulty concentrating.",
        ],
        sectionOrder: 11,
      },
    ],
  },
  {
    id: "17",
    name: "Cold and Flu",
    path: "cold-and-flu",
    genieMdName: "Cold and flu",
    primaryCategory: "Urgent Care",
    priceRange: "$7 to $21",
    description:
      "Cold and flu are both respiratory illnesses caused by different viruses. While they share some similar symptoms, they are caused by distinct viruses, and flu symptoms tend to be more severe. Both cold and flu viruses are highly contagious and spread through respiratory droplets when an infected person coughs or sneezes.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: [" Amoxicillin", "Albuterol", "Azithromycin 5 Day Dose Pack", "Benzonatate", "Oseltamivir", "Ondansetron", "HydrOXYzine Hydrochloride", "Doxycycline Hyclate", "Amoxicillin-Clavulanate", "MethylPREDNISolone Dose Pack", "Amoxicillin-Clavulanate", "Benzonatate", "Ciprofloxacin", "Tamiflu"],
    symptoms: [{
      sectionName: "Cold Symptoms",
      sectionDetails: ["Pain or discomfort in one or both ears.",
      "Sore Throat","Mild to moderate sore throat.","Nasal congestion or a runny nose.","A mild, hacking cough.","Frequent sneezing.","Eyes may be watery or itchy.","Generally mild fatigue and weakness.","Slight body aches or discomfort."],
      sectionOrder: 1,
    },{
      sectionName: "Flu Symptoms",
      sectionDetails: ["Symptoms typically develop abruptly.",
      "Often accompanied by a severe sore throat.",	"Similar to a cold, but symptoms are usually more intense.",	"A persistent, dry cough.",	"High fever and chills are common.",	"Profound fatigue and weakness.",	"Intense muscle and body aches.",	"Severe headache is common.",	"Chest discomfort or tightness.",	"Shortness of breath, especially with exertion."],
      sectionOrder: 1,
    },],
  },
  {
    id: "18",
    name: "Earache",
    path: "earache",
    genieMdName: "Earache - Adult",
    primaryCategory: "Urgent Care",
    priceRange: "$7 to $20",
    description:
      "Earaches in adults can be caused by various factors, including infections, inflammation, or other underlying conditions affecting the ear. An earache typically involves pain or discomfort in the ear and may be accompanied by other symptoms such as hearing loss, drainage, or a feeling of fullness in the ear.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Thermometer"],
    commonMedications: ["Antipyrine", "Benzocaine", "Amoxicillin", "Amoxicillin-Clavulanate", "Zithromax", "Cephalexin", "Ciprodex"],
    symptoms: [
      {
        sectionName: "Ear Pain",
        sectionDetails: ["Pain or discomfort in one or both ears."],
        sectionOrder: 1,
      },
      {
        sectionName: "Sharp or Dull Pain",
        sectionDetails: ["The pain may be sharp, stabbing, or a dull ache."],
        sectionOrder: 2,
      },
      {
        sectionName: "Fullness or Pressure",
        sectionDetails: ["Sensation of fullness or pressure in the ear."],
        sectionOrder: 3,
      },
      {
        sectionName: "Hearing Loss",
        sectionDetails: ["Temporary or partial hearing loss."],
        sectionOrder: 4,
      },
      {
        sectionName: "Tinnitus",
        sectionDetails: ["Ringing, buzzing, or other noises in the ear."],
        sectionOrder: 5,
      },
      {
        sectionName: "Discharge",
        sectionDetails: [
          "Fluid drainage from the ear, which may be clear, yellow, or bloody.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Fever",
        sectionDetails: [
          "In some cases, earaches may be accompanied by a fever.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Jaw or Neck Pain",
        sectionDetails: ["Pain or discomfort in the jaw or neck region."],
        sectionOrder: 8,
      },
      {
        sectionName: "Vertigo or Dizziness",
        sectionDetails: [
          "Sensation of spinning or dizziness may occur with certain ear conditions.",
        ],
        sectionOrder: 9,
      },
    ],
  },
  {
    id: "19",
    name: "Arthritis",
    path: "arthritis",
    genieMdName: "Arthritis",
    primaryCategory: "Urgent Care",
    priceRange: "$5 to $40",
    description:
      "Arthritis is a term that refers to the inflammation of one or more joints, leading to pain, swelling, stiffness, and decreased joint mobility. There are many types of arthritis, with the two most common being osteoarthritis and rheumatoid arthritis. Arthritis can affect people of all ages and is often associated with joint damage and degeneration.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Naproxen", "Ibuprophen", "Corticosteriods", "Hydroxychloroquin", "Indomethacin"],
    symptoms: [
      {
        sectionName: "Joint Pain",
        sectionDetails: [
          "Persistent pain in one or more joints, often worsened by movement or weight-bearing.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Joint Swelling",
        sectionDetails: [
          "Swelling and inflammation around the affected joints.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Joint Stiffness",
        sectionDetails: [
          "Stiffness, especially in the morning or after periods of inactivity.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Reduced Range of Motion",
        sectionDetails: [
          "Difficulty moving the joint through its full range of motion.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Warmth and Redness",
        sectionDetails: [
          "Affected joints may feel warm to the touch and appear red.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Fatigue",
        sectionDetails: [
          "Generalized tiredness or fatigue, especially in inflammatory types of arthritis.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Weakness",
        sectionDetails: [
          "Weakness in the muscles surrounding the affected joints.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Joint Deformities",
        sectionDetails: [
          "In some cases, arthritis can lead to joint deformities, particularly in chronic or severe cases.",
        ],
        sectionOrder: 8,
      },
      {
        sectionName: "Fever (In Some Types)",
        sectionDetails: [
          "Certain types of inflammatory arthritis may be associated with systemic symptoms, including fever",
        ],
        sectionOrder: 9,
      },
    ],
  },
  {
    id: "20",
    name: "Asthma",
    path: "asthma",
    genieMdName: "Asthma",
    primaryCategory: "Urgent Care",
    priceRange: "$5 to $18",
    description:
      "Asthma is a chronic respiratory condition characterized by inflammation of the airways, leading to recurrent episodes of wheezing, breathlessness, chest tightness, and coughing. These symptoms are often triggered by exposure to certain substances or environmental factors. Asthma can vary in severity, and individuals with asthma may experience periodic exacerbations or have persistent symptoms.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Albuterol", "Montelukast", "Fluticasone (Flovent HFA, Arnuity Ellipta, others)", "Budesonide", "Prednisone"],
    symptoms: [
      {
        sectionName: "Wheezing",
        sectionDetails: [
          "Whistling or squeaky sound produced during breathing, especially when exhaling.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Shortness of Breath",
        sectionDetails: [
          "Difficulty breathing, often accompanied by a feeling of tightness in the chest.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Coughing",
        sectionDetails: [
          "Persistent cough, particularly at night or in the early morning.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Chest Tightness",
        sectionDetails: [
          "Discomfort or pressure in the chest, sometimes described as a feeling of tightness.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Fatigue",
        sectionDetails: [
          "Tiredness or exhaustion, especially during or after asthma attacks.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Increased Respiratory Rate",
        sectionDetails: ["Rapid breathing in response to restricted airflow."],
        sectionOrder: 6,
      },
      {
        sectionName: "Difficulty Speaking",
        sectionDetails: [
          "Speech may become difficult during an asthma attack due to shortness of breath.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Symptoms Worsening at Night",
        sectionDetails: [
          "Nocturnal worsening of symptoms, known as nocturnal asthma.",
        ],
        sectionOrder: 8,
      },
      {
        sectionName: "Coughing or Wheezing with Exercise",
        sectionDetails: [
          "Exercise-induced symptoms, such as coughing or wheezing, also known as exercise-induced bronchoconstriction.",
        ],
        sectionOrder: 9,
      },
    ],
  },
  {
    id: "21",
    name: "Gout",
    path: "gout",
    genieMdName: "Gout",
    primaryCategory: "Urgent Care",
    priceRange: "$5 to $35",
    description:
      "Gout is a form of inflammatory arthritis characterized by the sudden onset of severe pain, swelling, redness, and tenderness in joints, often the big toe. It occurs due to the buildup of uric acid crystals in the joints, leading to inflammation and acute attacks. Uric acid is a normal byproduct of the breakdown of purines, which are substances found in certain foods and naturally occurring in the body.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Thermometer"],
    commonMedications: ["Colchicine", "Glucocorticoids", "Naproxen Sodium"],
    symptoms: [
      {
        sectionName: "Joint Pain",
        sectionDetails: [
          "Intense pain, usually in the big toe, but gout can affect other joints such as the ankles, knees, elbows, wrists, and fingers.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Swelling",
        sectionDetails: ["Swelling and inflammation of the affected joint."],
        sectionOrder: 2,
      },
      {
        sectionName: "Redness",
        sectionDetails: [
          "The skin over the affected joint becomes red and shiny.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Tenderness",
        sectionDetails: [
          "Touching or applying pressure to the affected joint is extremely painful.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Limited Range of Motion",
        sectionDetails: [
          "Joint mobility may be restricted during a gout attack.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Heat",
        sectionDetails: ["The affected joint may feel warm to the touch."],
        sectionOrder: 6,
      },
      {
        sectionName: "Recurrent Attacks",
        sectionDetails: [
          "Gout often presents as intermittent acute attacks followed by symptom-free periods.",
        ],
        sectionOrder: 7,
      },
    ],
  },
  {
    id: "22",
    name: "Birth Control",
    path: "birth-control",
    genieMdName: "Birth Control",
    primaryCategory: "Women's Health",
    priceRange: "$8 to $19",
    description:
      "Birth control medications, also known as contraceptives, are pharmaceutical compounds designed to prevent pregnancy. There are various types of birth control methods, and medications come in different forms, including oral contraceptives (birth control pills), patches, injections, intrauterine devices (IUDs), and implants. Each type of birth control medication works differently to inhibit conception or interfere with the reproductive process.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Norethindrone", "Norgestimate & Ethinyl Estradiol", "Norgestimate-Eth Estrad"],
    symptoms: [],
  },
  {
    id: "23",
    name: "General Consult",
    path: "general-consult",
    genieMdName: "General Consult",
    primaryCategory: "",
    priceRange: "$6 to $12",
    description: "Our General Consult treatment protocol can be used for those issues you just can't identify on your own and would like to discuss with one of our healthcare providers.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Picture Condition"],
    commonMedications: ["Ondansetron", "Azithromycin 5 Day Dose Pack", "Prednisone", "Amoxicillin", "PARoxetine", "Insulin Glargine", "Xiidra", "Tobramycin Ophthalmic solution", "Pramipexole", "Doxycycline Hyclate", "Clindamycin Topical cream", "Montelukast"],
    symptoms: [],
  },
  {
    id: "54",
    name: "General Consult - Pediatric",
    path: "general-consult-pediatric",
    genieMdName: "General Consult",
    primaryCategory: "Pediatric Health",
    priceRange: "$6 to $12",
    description: "Our General Consult - Pediatric treatment protocol can be used for those issues you just can't identify on your own and would like to discuss with one of our healthcare providers.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Picture Condition"],
    commonMedications: ["Ondansetron", "Azithromycin 5 Day Dose Pack", "Prednisone", "Amoxicillin", "PARoxetine", "Insulin Glargine", "Xiidra", "Tobramycin Ophthalmic solution", "Pramipexole", "Doxycycline Hyclate", "Clindamycin Topical cream", "Montelukast"],
    symptoms: [],
  },
  {
    id: "24",
    name: "Genital Warts",
    path: "genital-warts",
    genieMdName: "Genital Warts",
    primaryCategory: "Women's Health",
    priceRange: "$12 to $40",
    description:
      "Genital warts are a sexually transmitted infection (STI) caused by certain strains of the human papillomavirus (HPV). These warts can appear on or around the genital and anal areas. Genital warts are highly contagious and can be transmitted through sexual contact, including vaginal, anal, or oral sex. While they are usually painless, they can cause discomfort, and their presence may lead to psychological distress.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Picture Condition"],
    commonMedications: ["Podofilox Topical solution", "Imiquimod Topical cream", "Podofilox Topical solution"],
    symptoms: [
      {
        sectionName: "Wart Appearance",
        sectionDetails: [
          "Small, flesh-colored or gray growths that resemble cauliflower. They may be raised or flat and can occur singly or in clusters.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Genital Itching",
        sectionDetails: ["Itching or discomfort in the genital or anal area."],
        sectionOrder: 2,
      },
      {
        sectionName: "Bleeding",
        sectionDetails: [
          "Warts may bleed during sexual activity or if irritated.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Moisture or Dampness",
        sectionDetails: [
          "Warts may thrive in warm, moist areas, such as the genital or anal region.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Pain or Discomfort",
        sectionDetails: [
          "While genital warts are usually painless, some individuals may experience discomfort or tenderness.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Change in Urination",
        sectionDetails: [
          "Rarely, large warts may obstruct the urethra, causing difficulty urinating.",
        ],
        sectionOrder: 6,
      },
    ],
  },
  {
    id: "25",
    name: "Overactive Bladder",
    path: "overactive-bladder",
    genieMdName: "Overactive Bladder",
    primaryCategory: "Women's Health",
    priceRange: "$15 to $50",
    description:
      "Overactive bladder is a common condition characterized by a frequent and urgent need to urinate. It may involve involuntary contractions of the bladder muscle, leading to symptoms like urgency, frequency, and sometimes, urinary incontinence.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Oxybutynin", "Tolterodine", "Darifenacin", "Solifenacin", "Trospium", "Fesoterodine"],
    symptoms: [
      {
        sectionName: "Urgency",
        sectionDetails: [
          "Sudden, intense urge to urinate, often difficult to control.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Frequency",
        sectionDetails: ["Frequent need to urinate, exceeding usual patterns."],
        sectionOrder: 2,
      },
      {
        sectionName: "Nocturia",
        sectionDetails: ["Waking up at night to urinate, disrupting sleep."],
        sectionOrder: 3,
      },
      {
        sectionName: "Urge Incontinence",
        sectionDetails: [
          "Inability to reach the restroom in time, resulting in involuntary leakage.",
        ],
        sectionOrder: 4,
      },
    ],
  },
  {
    id: "27",
    name: "Yeast Infection",
    path: "yeast-infection",
    genieMdName: "Yeast Infection",
    primaryCategory: "Women's Health",
    priceRange: "$7 to $15",
    description:
      "A yeast infection, or candidiasis, is a common fungal infection caused by an overgrowth of the Candida yeast. It can affect various parts of the body, but vaginal yeast infections are particularly prevalent.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Fluconazole", "Miconazole Nitrate Vaginal Suppository"],
    symptoms: [
      {
        sectionName: "Vaginal Yeast Infection",
        sectionDetails: [
          "Itching and irritation in the vaginal area.",
          "Swelling and redness of the vulva.",
          "Pain or discomfort during urination or intercourse.",
          "Thick, white, odorless vaginal discharge.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Oral Yeast Infection (Thrush)",
        sectionDetails: [
          "White patches on the tongue and inside the mouth.",
          "Sore throat and difficulty swallowing.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Skin Yeast Infection",
        sectionDetails: [
          "Red, itchy rash with raised edges.",
          "Pimples or pustules around the edges of the rash.",
        ],
        sectionOrder: 3,
      },
    ],
  },
  {
    id: "28",
    name: "Erectile Dysfunction",
    path: "erectile-dysfunction",
    genieMdName: "Erectile dysfunction",
    primaryCategory: "Men's Health",
    priceRange: "$1 to $3 per tablet",
    description:
      "ED refers to the consistent inability to achieve or maintain an erection sufficient for sexual activity. It can result from various factors, including physical, psychological, or a combination of both.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "BP Reading"],
    commonMedications: ["Sildenafil Citrate", "Avanafil", "Tadalafil"],
    symptoms: [
      {
        sectionName: "Associated Symptoms",
        sectionDetails: [
          "Difficulty getting or keeping an erection.",
          "Reduced sexual desire.",
          "Emotional distress or relationship strain.",
        ],
        sectionOrder: 1,
      },
    ],
  },
  {
    id: "31",
    name: "Hair Loss",
    path: "hair-loss",
    genieMdName: "Hair loss",
    primaryCategory: "Men's Health",
    priceRange: "$9 to $20",
    description:
      "Hair loss, also known as alopecia, can occur due to various factors, including genetics, hormonal changes, medical conditions, and lifestyle choices. It may affect the scalp or other parts of the body, leading to different types of hair loss.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Picture Condition"],
    commonMedications: ["Finasteride", "Minoxidil"],
    symptoms: [
      {
        sectionName: "Associated Symptoms",
        sectionDetails: [
          "Gradual thinning on the top of the head (most common in men).",
          "Circular or patchy bald spots (common in some types of alopecia areata).",
          "Sudden loosening of hair (telogen effluvium).",
          "Full-body hair loss (alopecia universalis).",
        ],
        sectionOrder: 1,
      },
    ],
  },
  {
    id: "32",
    name: "Abdominal Pain - Pediatric",
    path: "abdominal-pain-pediatric",
    genieMdName: "Abdominal Pain - Pediatric",
    primaryCategory: "Pediatric Health",
    priceRange: "$6 to $9",
    description: "Abdominal pain in pediatric patients refers to discomfort or pain felt in the area between the chest and groin. It can range from mild discomfort to severe, debilitating pain and can be caused by various factors.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Sucralfate", "Omeprazol", "Ondansetron"],
    symptoms: [{
      sectionName: "Location",
      sectionDetails: [
        "The pain may be localized in a specific area of the abdomen or may be diffuse, covering a larger portion of the belly.",
      ],
      sectionOrder: 1,
    },{
      sectionName: "Intensity",
      sectionDetails: [
        "It can range from mild to severe, with some children experiencing debilitating pain that interferes with daily activities.",
      ],
      sectionOrder: 2,
    },{
      sectionName: "Duration",
      sectionDetails: [
        "Abdominal pain may be acute (sudden onset and short duration) or chronic (persistent or recurrent over a longer period).",
      ],
      sectionOrder: 3,
    },{
      sectionName: "Associated Symptoms:",
      sectionDetails: [
        "Nausea and vomiting",
        "Diarrhea or constipation",
        "Fever or chills",
        "Loss of appetite",
        "Bloating or abdominal distension",
        "Blood in stool or vomit",
        "Difficulty urinating or frequent urination",
        "Fatigue or weakness",
        "Irritability or changes in behavior",
        "Weight loss or failure to thrive",
        "Jaundice (yellowing of the skin and eyes)",
      ],
      sectionOrder: 4,
    },],
  },
  {
    id: "33",
    name: "Acute Cough - Pediatric",
    path: "acute-cough-pediatric",
    genieMdName: "Acute Cough - Pediatric",
    primaryCategory: "Pediatric Health",
    priceRange: "$6 to $15",
    description:
      "Acute cough in pediatric patients refers to a sudden onset of coughing that typically lasts for a short duration, often less than three weeks. It is a common symptom in childhood and can be caused by various factors, including viral infections, bacterial infections, environmental irritants, or allergic reactions. The cough itself is a reflex action that helps clear the airways of mucus, irritants, or foreign particles.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Thermometer"],
    commonMedications: ["Albuterol", "Prednisone", "Amoxicillin-Clavulanate"],
    symptoms: [
      {
        sectionName: "Cough Characteristics",
        sectionDetails: [
          "Dry Cough: A cough without the production of mucus or phlegm.",
          "Wet or Productive Cough: Involves the expulsion of mucus or phlegm.",
          "Barking Cough: Common in croup, a viral infection that affects the upper airways.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Associated Symptoms",
        sectionDetails: [
          "Runny Nose: Often seen with viral infections like the common cold.",
          "Sore Throat: Common in throat infections.",
          "Fever: Present in some cases, depending on the underlying cause.",
          "Wheezing: May indicate asthma or other respiratory conditions.",
          "Shortness of Breath: Seen in severe cases or conditions affecting the lower airways.",
        ],
        sectionOrder: 2,
      },
    ],
  },
  {
    id: "34",
    name: "Diarrhea - Pediatric",
    path: "diarrhea-pediatric",
    genieMdName: "Diarrhea - Pediatric",
    primaryCategory: "Pediatric Health",
    priceRange: "",
    description:
      "Diarrhea in infants aged 0-2 months refers to the passage of frequent, loose, or watery stools. It is essential to monitor and address diarrhea promptly in this age group, as infants are more vulnerable to dehydration and complications. Diarrhea in newborns may be caused by various factors, including infections, formula intolerance, or underlying medical conditions.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Thermometer"],
    commonMedications: ["Pedialyte"],
    symptoms: [
      {
        sectionName: "Frequent Bowel Movements",
        sectionDetails: [
          "Passage of stools that are more frequent than the infant's usual pattern.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Watery or Loose Stools",
        sectionDetails: [
          "Stools that are watery, loose, or have a different consistency than normal.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Increased Stool Volume",
        sectionDetails: ["An increase in the overall volume of stool passed."],
        sectionOrder: 3,
      },
      {
        sectionName: "Abdominal Discomfort",
        sectionDetails: [
          "Signs of abdominal discomfort, such as fussiness or increased crying.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Changes in Feeding Patterns",
        sectionDetails: [
          "Changes in feeding behavior, including refusal to feed or decreased interest in feeding.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Signs of Dehydration",
        sectionDetails: [
          "Signs of dehydration, including dry mouth, decreased urine output, sunken fontanelle (soft spot on the baby's head), or lethargy.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Fever",
        sectionDetails: [
          "In some cases, diarrhea may be accompanied by a fever.",
        ],
        sectionOrder: 7,
      },
    ],
  },
  {
    id: "35",
    name: "Earache - Pediatric",
    path: "earache-pediatric",
    genieMdName: "Earache - Pediatric",
    primaryCategory: "Pediatric Health",
    priceRange: "$7 to $20",
    description: "Earaches in children can be caused by various factors, including infections, inflammation, or other underlying conditions affecting the ear. An earache typically involves pain or discomfort in the ear and may be accompanied by other symptoms such as hearing loss, drainage, or a feeling of fullness in the ear.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Thermometer"],
    commonMedications: ["Antipyrine", "Benzocaine", "Amoxicillin", "Amoxicillin-Clavulanate", "Zithromax", "Cephalexin", "Ciprodex"],
    symptoms: [
      {
        sectionName: "Ear Pain",
        sectionDetails: [
          "Children may experience varying degrees of pain within the ear, ranging from mild discomfort to severe throbbing pain."
        ],
        sectionOrder: 1
      },
      {
        sectionName: "Irritability",
        sectionDetails: [
          "Infants and young children may exhibit increased fussiness or irritability, especially when lying down or during feeding due to changes in ear pressure."
        ],
        sectionOrder: 2
      },
      {
        sectionName: "Fever",
        sectionDetails: [
          "Ear infections, such as otitis media, can cause fever in children. The presence of fever may indicate a more severe infection."
        ],
        sectionOrder: 3
      },
      {
        sectionName: "Pulling or Tugging at the Ear",
        sectionDetails: [
          "Young children may tug or pull at their ears in an attempt to alleviate the discomfort."
        ],
        sectionOrder: 4
      },
      {
        sectionName: "Difficulty Sleeping",
        sectionDetails: [
          "Ear pain can disrupt sleep patterns, leading to difficulty falling asleep or staying asleep."
        ],
        sectionOrder: 5
      },
      {
        sectionName: "Changes in Appetite",
        sectionDetails: [
          "Children with earaches may show a decreased appetite due to pain or discomfort, particularly during feeding."
        ],
        sectionOrder: 6
      },
      {
        sectionName: "Fluid Drainage from the Ear",
        sectionDetails: [
          "In some cases, particularly with acute otitis media, fluid or pus may drain from the affected ear."
        ],
        sectionOrder: 7
      },
      {
        sectionName: "Difficulty Hearing",
        sectionDetails: [
          "Ear infections can sometimes cause temporary hearing loss or muffled hearing in the affected ear."
        ],
        sectionOrder: 8
      },
      {
        sectionName: "Balance Issues",
        sectionDetails: [
          "Severe ear infections may affect the vestibular system, leading to dizziness or balance problems."
        ],
        sectionOrder: 9
      }
    ]    
  },
  {
    id: "39",
    name: "Acne",
    path: "acne",
    genieMdName: "Acne",
    primaryCategory: "Dermatology",
    priceRange: "$6.50 to $67",
    description:
      "Acne is a common skin condition characterized by the development of pimples, blackheads, whiteheads, and inflamed nodules or cysts. It often occurs during adolescence but can affect individuals of any age.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Picture Condition"],
    commonMedications: ["Benzoyl Peroxide Gel", "Clindamycin Phosphate Soln", "Tretinoin Gel", "Tretinoin Cream"],
    symptoms: [
      {
        sectionName: "Non-Inflammatory Lesions",
        sectionDetails: [
          "Blackheads: Open comedones resulting from clogged pores.",
          "Whiteheads: Closed comedones with blocked pores under the skin",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Inflammatory Lesions",
        sectionDetails: [
          "Papules: Small, red, raised bumps.",
          "Pustules: Pimples containing pus.",
          "Nodules: Solid, painful lumps beneath the skin.",
          "Cysts: Deep, pus-filled lesions causing severe inflammation",
        ],
        sectionOrder: 2,
      },
    ],
  },
  {
    id: "41",
    name: "Dry Eye",
    path: "dry-eye",
    genieMdName: "Dry eye",
    primaryCategory: "Optometry",
    priceRange: "",
    description:
      "Dry eye, also known as dry eye syndrome or keratoconjunctivitis sicca, is a common condition characterized by a lack of sufficient lubrication and moisture on the surface of the eyes. This can result in discomfort, irritation, and a range of symptoms affecting the eyes' overall health. Dry eye may occur when there is an imbalance in the production and drainage of tears, leading to inadequate tear film.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Xiidra", "Cyclosporine"],
    symptoms: [
      {
        sectionName: "Dryness",
        sectionDetails: [
          "Persistent sensation of dryness in one or both eyes.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Itching or Burning",
        sectionDetails: ["Eyes may feel itchy or burning."],
        sectionOrder: 2,
      },
      {
        sectionName: "Redness",
        sectionDetails: [
          "The whites of the eyes (sclera) may appear red or bloodshot.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Foreign Body Sensation",
        sectionDetails: [
          "Feeling as if there is a foreign object or grit in the eyes.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Blurred Vision",
        sectionDetails: [
          "Vision may become temporarily blurred, especially during extended periods of visual tasks.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Sensitivity to Light",
        sectionDetails: ["Increased sensitivity to light (photophobia)."],
        sectionOrder: 6,
      },
      {
        sectionName: "Stringy Mucus in or around the Eyes",
        sectionDetails: [
          "Production of stringy mucus in the eyes, especially upon waking.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Discomfort with Contact Lenses",
        sectionDetails: [
          "Individuals who wear contact lenses may experience discomfort or intolerance.",
        ],
        sectionOrder: 8,
      },
      {
        sectionName: "Tearing",
        sectionDetails: [
          "Paradoxically, excessive tearing can be a response to dry eyes as the eyes attempt to compensate for the dryness.",
        ],
        sectionOrder: 9,
      },
      {
        sectionName: "Difficulty Driving at Night",
        sectionDetails: [
          "Increased difficulty with vision and discomfort while driving at night.",
        ],
        sectionOrder: 10,
      },
    ],
  },
  {
    id: "43",
    name: "Pinkeye",
    path: "pinkeye",
    genieMdName: "Pinkeye",
    primaryCategory: "Optometry",
    priceRange: "$6 to $14",
    description:
      "Pinkeye, or conjunctivitis, is an inflammation of the conjunctiva, the transparent membrane covering the white part of the eye and the inner surface of the eyelids. It can be caused by viruses, bacteria, allergens, or irritants.",
    whatYouNeed: [
      "Meds",
      "Allergies",
      "Conditions",
      "Picture Condition",
      "Thermometer",
    ],
    commonMedications: ["Ciprofloxacin HCl", "Erythromycin Ophth Oint", "Loratadine", "Ofloxacin Ophthalmic solution", "Dexamethasone/Neomycin/Polymyxin"],
    symptoms: [
      {
        sectionName: "Redness",
        sectionDetails: ["Pink or red appearance of the eyes."],
        sectionOrder: 1,
      },
      {
        sectionName: "Itching",
        sectionDetails: ["Irritation and desire to rub the eyes."],
        sectionOrder: 2,
      },
      {
        sectionName: "Tearing",
        sectionDetails: ["Excessive tearing or watering."],
        sectionOrder: 3,
      },
      {
        sectionName: "Discharge",
        sectionDetails: ["Watery or pus-like discharge."],
        sectionOrder: 4,
      },
      {
        sectionName: "Sensitivity to Light",
        sectionDetails: ["Increased sensitivity to light."],
        sectionOrder: 5,
      },
      {
        sectionName: "Blurry Vision",
        sectionDetails: ["Blurred vision in some cases."],
        sectionOrder: 6,
      },
    ],
  },
  {
    id: "43",
    name: "Pinkeye - Pediatric",
    path: "pinkeye-pediatric",
    genieMdName: "Pinkeye",
    primaryCategory: "Pediatric Health",
    priceRange: "$6 to $14",
    description:
      "Pinkeye, or conjunctivitis, is an inflammation of the conjunctiva, the transparent membrane covering the white part of the eye and the inner surface of the eyelids. It can be caused by viruses, bacteria, allergens, or irritants.",
    whatYouNeed: [
      "Meds",
      "Allergies",
      "Conditions",
      "Picture Condition",
      "Thermometer",
    ],
    commonMedications: ["Ciprofloxacin HCl", "Erythromycin Ophth Oint", "Loratadine", "Ofloxacin Ophthalmic solution", "Dexamethasone/Neomycin/Polymyxin"],
    symptoms: [
      {
        sectionName: "Redness",
        sectionDetails: ["Pink or red appearance of the eyes."],
        sectionOrder: 1,
      },
      {
        sectionName: "Itching",
        sectionDetails: ["Irritation and desire to rub the eyes."],
        sectionOrder: 2,
      },
      {
        sectionName: "Tearing",
        sectionDetails: ["Excessive tearing or watering."],
        sectionOrder: 3,
      },
      {
        sectionName: "Discharge",
        sectionDetails: ["Watery or pus-like discharge."],
        sectionOrder: 4,
      },
      {
        sectionName: "Sensitivity to Light",
        sectionDetails: ["Increased sensitivity to light."],
        sectionOrder: 5,
      },
      {
        sectionName: "Blurry Vision",
        sectionDetails: ["Blurred vision in some cases."],
        sectionOrder: 6,
      },
    ],
  },
  {
    id: "46",
    name: "Chronic Lower Back Pain",
    path: "chronic-lower-back-pain",
    genieMdName: "Chronic Lower Back Pain",
    primaryCategory: "Chronic Conditions",
    priceRange: "Varies",
    description:
      "Chronic lower back pain is persistent discomfort or pain in the lower part of the spine that lasts for an extended period, typically defined as lasting for 12 weeks or more. It can be caused by various factors, including muscle strain, ligament sprains, herniated discs, degenerative disc disease, spinal stenosis, or other underlying medical conditions. Chronic lower back pain may significantly impact daily activities, mobility, and overall quality of life.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Cyclobenzaprine", "Methocarbamol ", "Metaxalone"],
    symptoms: [
      {
        sectionName: "Persistent Pain",
        sectionDetails: [
          "Long-term or recurring pain in the lower back, ranging from a dull ache to sharp, shooting pain.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Stiffness",
        sectionDetails: [
          "Reduced flexibility and stiffness in the lower back, especially after periods of inactivity.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Muscle Tension",
        sectionDetails: ["Tightness or muscle spasms in the lower back."],
        sectionOrder: 3,
      },
      {
        sectionName: "Pain that Radiates",
        sectionDetails: [
          "Pain that may radiate into the buttocks, thighs, or legs, known as sciatica if it involves the sciatic nerve.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Discomfort with Movement",
        sectionDetails: [
          "Pain or discomfort that worsens with certain movements or activities.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Limited Range of Motion",
        sectionDetails: [
          "Difficulty bending, twisting, or performing certain movements due to pain or stiffness.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Impaired Function",
        sectionDetails: [
          "Impact on daily activities, work, or exercise due to lower back pain.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Sleep Disturbances",
        sectionDetails: [
          "Difficulty finding a comfortable sleeping position and disrupted sleep.",
        ],
        sectionOrder: 8,
      },
    ],
  },
  {
    id: "47",
    name: "Chronic Cough",
    path: "chronic-cough",
    genieMdName: "Chronic cough",
    primaryCategory: "Chronic Conditions",
    priceRange: "$6 to $12",
    description:
      "Chronic cough is defined as a persistent cough that lasts for eight weeks or longer in adults. It is a common symptom and can be caused by various underlying conditions. Chronic cough can significantly impact daily life, disrupt sleep, and indicate an ongoing issue that may require medical attention.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Dextromethorphan", "Benzonatate", "Gabapentin", "Pregabalin"],
    symptoms: [
      {
        sectionName: "Duration",
        sectionDetails: ["A cough that persists for eight weeks or longer."],
        sectionOrder: 1,
      },
      {
        sectionName: "Dry or Productive Cough",
        sectionDetails: [
          "The cough may be dry (non-productive) or produce mucus (productive).",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Cough Triggers",
        sectionDetails: [
          "Cough may be triggered or worsened by factors such as exposure to irritants, cold air, or certain activities.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Respiratory Symptoms",
        sectionDetails: [
          "Associated symptoms may include wheezing, shortness of breath, or chest tightness.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Throat Irritation",
        sectionDetails: [
          "Chronic irritation or tickling sensation in the throat.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Postnasal Drip",
        sectionDetails: [
          "Excessive mucus production and dripping down the back of the throat.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Gastroesophageal Reflux (GERD)",
        sectionDetails: [
          "Chronic cough may be associated with GERD, leading to stomach acid irritating the airways.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Hoarseness",
        sectionDetails: ["Changes in voice or hoarseness may be present."],
        sectionOrder: 8,
      },
      {
        sectionName: "Fatigue",
        sectionDetails: [
          "Chronic cough can lead to fatigue due to disturbed sleep patterns.",
        ],
        sectionOrder: 9,
      },
      {
        sectionName: "Weight Loss",
        sectionDetails: [
          "In severe cases or when associated with other symptoms, weight loss may occur.",
        ],
        sectionOrder: 10,
      },
    ],
  },
  {
    id: "48",
    name: "Depression",
    path: "depression",
    genieMdName: "Depression",
    primaryCategory: "Mental Wellness",
    priceRange: "Varies",
    description:
      "Depression is a mental health disorder characterized by persistent feelings of sadness, hopelessness, and a lack of interest or pleasure in activities. It affects how individuals think, feel, and handle daily activities, often interfering with their ability to function. Depression is a common and serious condition that can have a significant impact on various aspects of life.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Bupropion", "Buspirone", "Citalopram Hydrobromide", "Escitalopram Oxalate", "Sertraline"],
    symptoms: [
      {
        sectionName: "Persistent Sadness",
        sectionDetails: [
          "Feeling sad, empty, or having a consistently low mood.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Loss of Interest or Pleasure",
        sectionDetails: [
          "A marked decrease or loss of interest in activities once enjoyed, including hobbies, socializing, or work.",
        ],
        sectionOrder: 2,
      },
      {
        sectionName: "Fatigue and Lack of Energy",
        sectionDetails: [
          "Persistent feelings of fatigue, low energy, and a sense of being easily exhausted.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Changes in Sleep Patterns",
        sectionDetails: [
          "Insomnia (difficulty falling or staying asleep) or hypersomnia (excessive sleeping).",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Appetite or Weight Changes",
        sectionDetails: [
          "Significant changes in appetite, leading to weight loss or gain.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Feelings of Worthlessness or Guilt",
        sectionDetails: [
          "Persistent feelings of worthlessness, guilt, or self-blame.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Difficulty Concentrating",
        sectionDetails: [
          "Trouble concentrating, making decisions, or remembering things.",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Irritability or Restlessness",
        sectionDetails: ["Increased irritability, restlessness, or agitation."],
        sectionOrder: 8,
      },
      {
        sectionName: "Physical Symptoms",
        sectionDetails: [
          "Physical symptoms such as headaches, stomachaches, or chronic pain without a clear medical cause.",
        ],
        sectionOrder: 9,
      },
      {
        sectionName: "Withdrawal from Activities",
        sectionDetails: [
          "Withdrawal from social activities and a tendency to isolate oneself.",
        ],
        sectionOrder: 10,
      },
      {
        sectionName: "Sleep Disturbances",
        sectionDetails: [
          "Changes in sleep patterns, including insomnia or oversleeping.",
        ],
        sectionOrder: 11,
      },
    ],
  },
  {
    id: "49",
    name: "Allergies",
    path: "allergies",
    genieMdName: "Allergies",
    primaryCategory: "Allergies",
    priceRange: "$5 to $28",
    description:
      "Allergies, also known as allergic reactions, occur when the immune system reacts to substances (allergens) that are usually harmless. Common allergens include pollen, dust mites, pet dander, certain foods, insect stings, and certain medications. Allergic reactions can range from mild to severe and may involve various symptoms affecting the respiratory system, skin, digestive system, or other parts of the body.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: ["Azelastine HCl Nasal Spray", "Levocetirizine Dihydrochloride Tab", "Montelukast"],
    symptoms: [
      {
        sectionName: "Sneezing",
        sectionDetails: [
          "Repeated bouts of sneezing, especially in response to allergens like pollen or dust.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Runny or Stuffy Nose",
        sectionDetails: ["Nasal congestion, runny nose, or postnasal drip."],
        sectionOrder: 2,
      },
      {
        sectionName: "Itchy or Watery Eyes",
        sectionDetails: [
          "Itchy, watery, or red eyes, known as allergic conjunctivitis.",
        ],
        sectionOrder: 3,
      },
      {
        sectionName: "Skin Reactions",
        sectionDetails: [
          "Itchy skin, hives, eczema, or other allergic skin reactions.",
        ],
        sectionOrder: 4,
      },
      {
        sectionName: "Coughing",
        sectionDetails: [
          "Persistent cough, especially in response to airborne allergens.",
        ],
        sectionOrder: 5,
      },
      {
        sectionName: "Wheezing",
        sectionDetails: [
          "Wheezing or shortness of breath, particularly in individuals with allergic asthma.",
        ],
        sectionOrder: 6,
      },
      {
        sectionName: "Swelling",
        sectionDetails: [
          "Swelling of the face, lips, tongue, or other parts of the body (angioedema).",
        ],
        sectionOrder: 7,
      },
      {
        sectionName: "Fatigue",
        sectionDetails: [
          "Tiredness or fatigue, which may be related to the body's immune response.",
        ],
        sectionOrder: 8,
      },
      {
        sectionName: "Digestive Symptoms",
        sectionDetails: [
          "Nausea, vomiting, abdominal pain, or diarrhea in response to food allergens.",
        ],
        sectionOrder: 9,
      },
      {
        sectionName: "Anaphylaxis (Severe Allergic Reaction)",
        sectionDetails: [
          "A life-threatening reaction that can cause difficulty breathing, a drop in blood pressure, loss of consciousness, and other severe symptoms. Anaphylaxis requires immediate medical attention.",
        ],
        sectionOrder: 10,
      },
    ],
  },
  {
    id: "50",
    name: "Eczema",
    path: "eczema",
    genieMdName: "Eczema",
    primaryCategory: "Dermatology",
    priceRange: "$5 to $125",
    description:
      "Eczema, also known as atopic dermatitis, is a chronic inflammatory skin condition characterized by dry, itchy, red, and inflamed patches on the skin. It commonly appears in early childhood, although it can persist into adulthood or develop later in life. Eczema is not contagious and varies in severity from person to person. The exact cause of eczema is not fully understood, but it is believed to involve a combination of genetic, environmental, and immune system factors.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Picture Condition"],
    commonMedications: ["Topical Corticosteroids","Topical Calcineurin Inhibitors","Antihistamines"],
    symptoms: [
      {
        sectionName: "Itching",
        sectionDetails: [
          "The hallmark symptom of eczema is intense itching, which can be debilitating and lead to scratching, further exacerbating the condition.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Dryness",
        sectionDetails: ["Eczema-prone skin is often dry, rough, and sensitive. The affected areas may become cracked, scaly, or flaky, especially during flare-ups."],
        sectionOrder: 2,
      },
      {
        sectionName: "Redness and Inflammation",
        sectionDetails: ["The skin affected by eczema typically appears red or inflamed. In severe cases, it may develop blisters or crust over."],
        sectionOrder: 3,
      },
      {
        sectionName: "Rashes and Patches",
        sectionDetails: ["Eczema commonly presents as patches of irritated skin, which may vary in size and location. These patches can occur anywhere on the body but are often found on the hands, feet, arms, elbows, knees, face, and neck."],
        sectionOrder: 4,
      },
      {
        sectionName: "Thickened Skin",
        sectionDetails: ["With chronic scratching and rubbing, the skin affected by eczema may become thickened or leathery."],
        sectionOrder: 5,
      },
      {
        sectionName: "Bleeding or Weeping",
        sectionDetails: ["In severe cases, eczema patches may bleed or ooze fluid, particularly if scratched excessively."],
        sectionOrder: 6,
      },
    ],
  },
  {
    id: "51",
    name: "Acute Sinusitis",
    path: "acute-sinusitis",
    genieMdName: "Acute Sinusitis",
    primaryCategory: "Urgent Care",
    priceRange: "$2 to $16",
    description:
      "Acute sinusitis refers to the inflammation and swelling of the sinus cavities, which are the air-filled spaces within the bones of the face and skull. This condition typically occurs as a result of viral, bacterial, or fungal infections, or due to allergies or structural issues within the nasal passages. Acute sinusitis is characterized by symptoms such as nasal congestion, facial pain or pressure, sinus headaches, postnasal drip, cough, and reduced sense of smell. It can be a short-term condition, with symptoms lasting less than four weeks, but it can cause significant discomfort and impact daily life during that time.",
    whatYouNeed: ["Meds", "Allergies", "Conditions"],
    commonMedications: [
      "Fluticasone",
      "Mometasone",
      "Amoxicillin",
      "Amoxicillin-clavulanate",
      "Doxycycline",
      "Loratadine",
      "Cetirizine",
    ],
    symptoms: [
      {
        sectionName: "Nasal Congestion",
        sectionDetails: [
          "Blockage of the nasal passages due to inflammation and swelling of the sinus tissues, leading to difficulty breathing through the nose.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Facial Pain or Pressure",
        sectionDetails: ["Pain or pressure sensation in the face, particularly around the cheeks, forehead, eyes, and nose. This discomfort often worsens when bending forward or lying down."],
        sectionOrder: 2,
      },
      {
        sectionName: "Sinus Headaches",
        sectionDetails: ["Headaches characterized by a deep, constant pain in the forehead, cheeks, or bridge of the nose, often worsened by movement or changes in atmospheric pressure."],
        sectionOrder: 3,
      },
      {
        sectionName: "Postnasal Drip",
        sectionDetails: ["Excessive mucus production that drips down the back of the throat, leading to throat irritation, cough, and sometimes a sore throat."],
        sectionOrder: 4,
      },
      {
        sectionName: "Cough",
        sectionDetails: ["A persistent cough, which may be dry or produce phlegm, is common in acute sinusitis, especially as a result of postnasal drip."],
        sectionOrder: 5,
      },
      {
        sectionName: "Reduced Sense of Smell",
        sectionDetails: ["Inflammation and congestion of the nasal passages can impair the sense of smell or taste."],
        sectionOrder: 6,
      },
      {
        sectionName: "Fatigue",
        sectionDetails: ["Feeling tired or fatigued is common, especially if sinusitis disrupts sleep due to symptoms like nasal congestion or coughing."],
        sectionOrder: 7,
      },
    ],
  },
  {
    id: "52",
    name: "Anti-Aging",
    path: "anti-aging",
    genieMdName: "Anti - Aging",
    primaryCategory: "Dermatology",
    priceRange: "$54 to $105",
    description:
      "Retinoids are a class of compounds derived from vitamin A that are widely used in skincare for their anti-aging properties. They work by increasing cell turnover, stimulating collagen production, and improving skin texture and elasticity. Retinoids can effectively reduce the appearance of wrinkles, fine lines, and other signs of aging, as well as address concerns such as hyperpigmentation, acne, and uneven skin tone. These compounds are available in various forms, including prescription-strength retinoids like tretinoin (Retin-A) and over-the-counter retinol products. Retinoids are typically applied topically to the skin and are often incorporated into nighttime skincare routines due to their potential to increase sensitivity to sunlight. Tretinoin is approximately 15 times more potent that retinol (main active ingredient in over-the-counter products).",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Picture Condition"],
    commonMedications: [
      "Tazarotene",
      "Tretinoin",
      "Tretinoin micro"
    ],
    symptoms: [
      {
        sectionName: "Fine Lines and Wrinkles",
        sectionDetails: [
          "Retinoids are effective in minimizing the appearance of fine lines and wrinkles, particularly those associated with aging and sun damage.",
        ],
        sectionOrder: 1,
      },
      {
        sectionName: "Uneven Skin Tone",
        sectionDetails: ["Retinoids can help improve skin tone by fading dark spots, hyperpigmentation, and discoloration, resulting in a more even complexion."],
        sectionOrder: 2,
      },
      {
        sectionName: "Texture Irregularities",
        sectionDetails: ["By promoting cell turnover and exfoliation, retinoids contribute to smoother, softer skin texture and reduced roughness or unevenness."],
        sectionOrder: 3,
      },
      {
        sectionName: "Loss of Firmness",
        sectionDetails: ["Retinoids stimulate collagen synthesis and improve skin elasticity, helping to counteract the loss of firmness and sagging skin associated with aging."],
        sectionOrder: 4,
      },
      {
        sectionName: "Acne and Blemishes",
        sectionDetails: ["Retinoids are effective in treating acne by unclogging pores, reducing inflammation, and preventing the formation of new blemishes."],
        sectionOrder: 5,
      },
      {
        sectionName: "Dullness",
        sectionDetails: ["Regular use of retinoids can impart a radiant and youthful glow to the skin by promoting cell renewal and enhancing overall skin health."],
        sectionOrder: 6,
      },
      {
        sectionName: "Sun Damage",
        sectionDetails: ["Retinoids have been shown to repair sun-damaged skin and minimize the appearance of sunspots and photodamage over time."],
        sectionOrder: 7,
      },
    ],
  },
  {
    id: "31",
    name: "Female Hair Loss",
    path: "female-hair-loss",
    genieMdName: "Hair loss",
    primaryCategory: "Women's Health",
    priceRange: "$9 to $20",
    description:
      "Hair loss, also known as alopecia, can occur due to various factors, including genetics, hormonal changes, medical conditions, and lifestyle choices. It may affect the scalp or other parts of the body, leading to different types of hair loss.",
    whatYouNeed: ["Meds", "Allergies", "Conditions", "Picture Condition"],
    commonMedications: ["Finasteride", "Minoxidil"],
    symptoms: [
      {
        sectionName: "Associated Symptoms",
        sectionDetails: [
          "Circular or patchy bald spots (common in some types of alopecia areata).",
          "Sudden loosening of hair (telogen effluvium).",
          "Full-body hair loss (alopecia universalis).",
        ],
        sectionOrder: 1,
      },
    ],
  },
  {
    id: "55",
    name: "Weight Loss",
    path: "weight-loss",
    genieMdName: "Weight Loss",
    primaryCategory: "",
    priceRange: "$510 to $530",
    description: "Achieving and maintaining a health weight is important for overall health and can reduce the risk of numerous chronic conditions, including heart disease, diabetes, hypertension, and certain cancers.",
    whatYouNeed: ["PCP","Vitals","Meds", "Allergies", "Conditions",],
    commonMedications: ["Semaglutide: Semaglutide is a medication belonging to the class of glucagon-like peptide-1 (GLP-1) receptor agonists. It is used primarily for the treatment of type 2 diabetes mellitus (T2DM) and obesity. Semaglutide works by mimicking the action of naturally occurring GLP-1 hormones in the body, which play a crucial role in 	regulating blood sugar levels, appetite, and body weight."],
    symptoms: [],
  },
];
