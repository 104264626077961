import { Navbar } from '../components/Navbar.jsx'
import React from 'react';
import {
  Box, Flex, Heading, Text, Image, Button, List, ListItem, ListIcon
} from "@chakra-ui/react";
import { MdCheckCircle } from 'react-icons/md';
import { Footer } from '../components/Footer.jsx'

export const SymliphySavingsCard = () => {

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      <Navbar />
      <style>
        {`
          @media print {
            body * {
              visibility: hidden;
            }
            .printable, .printable * {
              visibility: visible;
            }
            .print-hide {
              display:none;
            }
            .printable {
              position: absolute;
              top: 50px;
              left: 0;
              right: 0;
              margin: 0 auto;
            }

          }
        `}
      </style>
      <Flex justifyContent={'center'} py={12} px={5} 
        maxW={'1280px'} w={'100%'} margin={'0 auto'} 
        flexDirection={'column'} alignItems={'center'} gap={3}
      >
        <Heading as="h1" size={{base:"lg",md:"xl"}} mb={{base:6,md:12}} className='print-hide'>Symliphy Rx Savings Card</Heading>
        <Flex gap={{base:6,md:12}} direction={{base:'column',md:'row'}} w={'100%'}>
          <Flex direction={'column'} className="printable" w={'100%'} alignItems={'center'} gap={3}>
            <Image src="/card.png" w="100%" maxW="500px" border="solid 3px"></Image>
            <Text fontSize={'1.1em'} fontWeight='bold'>Show this savings card at your pharmacy!</Text>
            <Button onClick={handlePrint} variant='primaryOrangeOutline' size='lg' display={{base:'block',md:'none'}} className='print-hide'>Print Card</Button>
          </Flex>
          <Box w={'100%'} mt={{base:0,md:5}} className='print-hide'>
            <Text fontSize={'1.3em'} fontWeight={'bold'} mb={3}>Prescription Savings Card Benefits</Text>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Access to discounts are provided at no cost
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Works at any participating pharmacy location
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Never expires, can be used as often as you like 
              </ListItem>
            </List>
            <Button onClick={handlePrint} variant='primaryOrangeOutline' size='lg' mt={5} display={{base:'none',md:'block'}}>Print Card</Button>
          </Box>
        </Flex>
        
      </Flex>
      <Flex maxW={'1280px'} w={'100%'} margin={'0 auto'} gap={3} pb={16} className='print-hide'>
        <Text fontSize={'1.3em'} pt={3} px={5} textAlign={{base:'start',md:'center'}}>With the Symliphy Rx Savings Card, you can save up to 80% on medications. Just present your card to your pharmacist at your participating pharmacy to automatically get a discount on generic and branded medications. Because our discount program isn't insurance, there's no cost to use the card. The Symliphy Rx Savings Card is available for use by those with commercial insurance, Medicare or other government funded benefits, limited drug coverage, high deductible plans, as well as cash-paying patients. Your Symliphy Rx Savings Card cannot be used in conjunction with your insurance, but may be lower than your insurance price. Please ask your pharmacist for help in evaluating your price options.</Text>
      </Flex>
      
      <Footer />
    </>
  )
}